import './calculationPointListItem.scoped.scss'
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import React from "react"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import { ToolTip } from "components/common/toolTip/toolTip"

export interface CalculationPointListItemProps {
  point: CalculationPoint
  onClickDelete: () => void
  onClickShowInMap: () => void
}

export const CalculationPointListItem = (props: CalculationPointListItemProps) => {
  return (
    <div className="calculation-point-list-item row align-center gap1 space-between">
      <span>{props.point.name ?? "(uten navn)"}</span>
      <span className="row gap1">
        <ToolTip content="Slett">
          <ClearButton iconName="delete" onClick={props.onClickDelete} ariaLabel="Slett"></ClearButton>
        </ToolTip>
        <ToolTip content="Sentrer i kartet" defaultPosition="top-end">
          <ClearButton
            iconName="crosshairs"
            onClick={props.onClickShowInMap}
            ariaLabel="Sentrer i kartet"
          ></ClearButton>
        </ToolTip>
      </span>
    </div>
  )
}

