import './textInput.scoped.scss'
import { ChangeEvent, FocusEvent, ClipboardEvent } from "react"

export interface TextInputProps {
  name: string
  value: string | number
  isInvalid: boolean
  errors?: string
  onChange: (event: ChangeEvent) => void
  onBlur: (event: FocusEvent) => void
  onPaste?: (event: ClipboardEvent) => void
}

export const TextInput = (props: TextInputProps) => {
  return (
    <>
      <input
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={`input ${props.isInvalid ? 'invalid' : ''}`}
        onPaste={props.onPaste}
      />
      {props.isInvalid && (
        <div className={'validation-error column gap1 mt1'}>
          {props.errors}
        </div>
      )}
    </>
  )
}

