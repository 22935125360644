import { FC } from "react"
import './bigActionButton.scoped.scss'

export interface BigActionButtonProps {
  text?: string
  onClick?: () => void
  color?: "green" | "yellow"
}

export const BigActionButton: FC<BigActionButtonProps> = (props) => {
  return (
    <button
      type={"button"}
      className={`button big-action-button ${props.color ?? 'green'}`}
      onClick={props.onClick}
    >
      { props.text !== undefined? props.text : props.children }
    </button>
  )
}

