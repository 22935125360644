import {
  FC,
  ReactNode,
  useRef,
  useState,
} from "react"
import './toolTip.scoped.scss'
import {
  arrow,
  flip,
  FloatingArrow,
  offset,
  shift,
  useFloating,
  useHover,
  useInteractions,
} from '@floating-ui/react'

export interface ToolTipProps {
  content: ReactNode
  defaultPosition?: "top-start"
    | "top-end"
    | "right-start"
    | "right-end"
    | "bottom-start"
    | "bottom-end"
    | "left-start"
    | "left-end"
    | "top"
    | "right"
    | "bottom"
    | "left"
}

export const ToolTip: FC<ToolTipProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const arrowRef = useRef(null)

  const arrowHeightPx = 7
  const arrowGapPx = 2

  const {
    x,
    y,
    strategy,
    refs,
    context,
  } = useFloating({
    placement: props.defaultPosition ?? "top-start",
    open: isOpen,
    onOpenChange: setIsOpen,
    middleware: [
      offset(arrowHeightPx + arrowGapPx),
      flip(),
      shift(),
      arrow({ element: arrowRef }),
    ],
  })

  const hover = useHover(context)
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
  ])

  return (
    <>
      <span ref={refs.setReference} {...getReferenceProps()}>
        {props.children}
      </span>
      {isOpen && (
        <div ref={refs.setFloating} className="tooltip"
          style={{
            position: strategy,
            top: y ?? 0,
            left: x ?? 0,
            // width: 'max-content',
          }}
          {...getFloatingProps()}
        >
          {props.content}
          {/*/!*<div  className="arrow"></div>*!/*/}
          <FloatingArrow
            ref={arrowRef}
            context={context}
            fill="#dfe4e8"
          />
        </div>
      )}
    </>
  )
}

