import { NoiseSourceResponse_Scenario } from "services/sicalcApi/responses/noiseSourceResponse"
import { useEffect, useState } from "react"
import { NoiseCategoryGroupResponse } from "services/sicalcApi/responses/noiseCategoryGroupResponse"
import { CreateCustomScenarioRequest } from "services/sicalcApi/requests/createCustomScenarioRequest"
import styles from 'components/createCustomScenarioModal/createCustomScenarioModal.module.scss'
import toast from "react-hot-toast"
import { useFormik } from "formik"
import * as yup from 'yup'
import React from 'react'
import { useSicalcApiService } from "../../services/sicalcApi/sicalcApiService"
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { ModalProps } from "services/modal/modalService"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import { LightButton } from "components/common/buttons/lightButton/lightButton"

interface CreateCustomScenarioModalProps extends ModalProps {
  scenarios: NoiseSourceResponse_Scenario[] | undefined
  calculationTaskId: string
  onCreated?: () => void
}

interface Modifier {
  noiseCategoryGroupName: string
  noiseCategoryGroupId: string
  scalingFactor: number | string
}

interface FormDataModel {
  baseScenarioId: string
  customScenarioName: string
  targetYear: string | number
  selectedGroupId: string
  modifiers: Modifier[]
}

const formValidationSchema = yup.object().shape({
  baseScenarioId: yup.string()
    .required("Påkrevd"),
  customScenarioName: yup.string()
    .required("Påkrevd"),
  targetYear: yup.number()
    .typeError('Må være et tall')
    .min(0, "Må være positivt")
    .required("Påkrevd"),
  selectedGroupId: yup.string()
    .required("Påkrevd"),
  modifiers: yup.array()
    .of(yup.object().shape({
      scalingFactor: yup.number()
        .typeError("Må være et tall")
        .required("Påkrevd")
        .min(0, "Må være positiv"),
    }))
    .min(1, "Legg til minst  én flykategori"),
})

export function CreateCustomScenarioModal(props: CreateCustomScenarioModalProps) {
  const [ noiseCategoryGroups, setNoiseCategoryGroups ] = useState<NoiseCategoryGroupResponse[]>()
  const { customScenarioApi, noiseCategoryApi } = useSicalcApiService()
  useEffect(() => {
    noiseCategoryApi.getAllNoiseCategoryGroups()
      .then((response) => setNoiseCategoryGroups(response.groups))
  }, [ noiseCategoryApi])

  const handleSubmission = async (values: FormDataModel) => {
    const { baseScenarioId, customScenarioName, targetYear, modifiers } = values
    const request: CreateCustomScenarioRequest = {
      baseScenarioId: baseScenarioId,
      name: customScenarioName,
      targetYear: targetYear as number,
      modifiers: modifiers.map(modifier => ({
        noiseCategoryGroupId: modifier.noiseCategoryGroupId,
        scalingFactor: Number(modifier.scalingFactor),
      })),
    }
    await customScenarioApi.create(props.calculationTaskId, request)

    props.modalContext.close(true)

    if (props.onCreated !== undefined) {
      props.onCreated()
    }
  }

  const initialValues: FormDataModel = {
    baseScenarioId: '',
    customScenarioName: '',
    targetYear: '',
    selectedGroupId: '',
    modifiers: [],
  }

  const formik = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: handleSubmission,
  })

  const addModifierForSelectedGroup = () => {
    const group = noiseCategoryGroups?.find(g => g.id === formik.values.selectedGroupId)
    if (!group) {
      toast.error(`'Flykategorien finnes ikke`)
      return
    }

    const { modifiers, selectedGroupId } = formik.values
    const modifierAlreadyAdded = modifiers.some(m => m.noiseCategoryGroupId === selectedGroupId)
    if (modifierAlreadyAdded) {
      toast.error(`'${group.name}' er allerede lagt til`)
      return
    }
    const newModifier: Modifier = {
      noiseCategoryGroupName: group.name,
      noiseCategoryGroupId: group.id,
      scalingFactor: 1,
    }

    formik.setFieldValue('modifiers', [...formik.values.modifiers, newModifier])
  }

  const removeModifier = (index: number) => {
    const { modifiers } = formik.values
    modifiers.splice(index, 1)
    formik.setFieldValue('modifiers', [...modifiers])
    formik.setFieldTouched(`modifiers[${index}]`, false)
  }

  const setScalingFactor = (modifierIndex: number, newValueString: string) => {
    // Allow commas as decimal markers
    const newValue = newValueString.replace(",", ".")
    const { modifiers } = formik.values
    const modifier = modifiers[modifierIndex]
    modifier.scalingFactor = newValue
    formik.setFieldValue('modifiers', [...modifiers])
  }

  const dismiss = () => {
    props.modalContext.dismiss()
  }

  const modifierHasValidationErrors = (index: number) => {
    return formik.errors.modifiers
      && Array.isArray(formik.errors.modifiers)
      && formik.errors.modifiers[index]
      && formik.touched.modifiers
      && formik.touched.modifiers[index]
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      heading="Nytt scenario"
      actions={
        <>
          <LightButton
            onClick={dismiss}
          >
            Avbryt
          </LightButton>
          <PrimaryButton
            onClick={formik.handleSubmit}
            submit={true}
          >
            Opprett
          </PrimaryButton>
        </>
      }
    >

      <div className={`${styles.body} column`}>
        <label className={'column'}>
          <span className={'form-label '}>Velg hvilket scenario som skal skaleres</span>
          <select
            name={"baseScenarioId"}
            value={formik.values.baseScenarioId}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`input ${formik.touched.baseScenarioId && formik.errors.baseScenarioId ? 'invalid' : ''}`}
          >
            <option disabled value={''}>Velg ...</option>
            {props.scenarios?.map(s => (
              <option value={s.id} key={s.id}>
                {s.name}
              </option>
            ))}
          </select>
          {formik.touched.baseScenarioId && formik.errors.baseScenarioId && (
            <div className={'validation-error'}>
              {formik.errors.baseScenarioId}
            </div>
          )}
        </label>
        <label className={'column'}>
          <span className={'form-label '}>Navn</span>
          <input
            name={"customScenarioName"}
            value={formik.values.customScenarioName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`input ${formik.touched.customScenarioName && formik.errors.customScenarioName ? 'invalid' : ''}`}
            type="text"
          />
          {formik.touched.customScenarioName && formik.errors.customScenarioName && (
            <div className={'validation-error'}>
              {formik.errors.customScenarioName}
            </div>
          )}
        </label>
        <label className={'column'}>
          <span className={'form-label '}>Beregningsår</span>
          <input
            name={"targetYear"}
            value={formik.values.targetYear}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            className={`input ${formik.touched.targetYear && formik.errors.targetYear ? 'invalid' : ''}`}
            type="text"
          />
          {formik.touched.targetYear && formik.errors.targetYear && (
            <div className={'validation-error'}>
              {formik.errors.targetYear}
            </div>
          )}
        </label>
        <label className={'column'}>
          <span className={'form-label'}>Legg til filter / flykategori</span>
          <div className={'row'}>
            <select
              name={"selectedGroupId"}
              value={formik.values.selectedGroupId}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              className={`input ${formik.touched.selectedGroupId && formik.errors.selectedGroupId ? 'invalid' : ''}`}
            >
              <option disabled value={''}>Velg ...</option>
              { noiseCategoryGroups?.map((group, index) => (
                <option value={group.id} key={group.id}>
                  {group.name}
                </option>
              ))}
            </select>
            <button
              className={'ml1'}
              onClick={addModifierForSelectedGroup}
              disabled={!formik.values.selectedGroupId}
            >+</button>
          </div>
          {formik.touched.selectedGroupId && formik.errors.selectedGroupId && (
            <div className={'validation-error'}>
              {formik.errors.selectedGroupId}
            </div>
          )}
          {
            formik.errors.modifiers
              && !Array.isArray(formik.errors.modifiers)
              && formik.touched.modifiers
              && (
                <div className={'validation-error'}>
                  {formik.errors.modifiers}
                </div>
              )
          }
        </label>
        {
          formik.values.modifiers.length > 0
            && <div className={`${styles.modifiers} column`}>
              <div className={`${styles['modifier-header-row']} row space-between`}>
                <span className={styles['modifier-header-row-label']}>Filter / flykategori</span>
                <span className={styles['modifier-header-row-label']}>Skalering</span>
              </div>
              {
                formik.values.modifiers.map((modifier, index) => (
                  <React.Fragment key={index}>
                    <label
                      className={`${styles['modifier-row']} row align-center space-between`}
                    >
                      <span className={styles['modifier-label']}>
                        {modifier.noiseCategoryGroupName}
                      </span>
                      <div className={'row'}>
                        <input
                          className={`input ${modifierHasValidationErrors(index) ? 'invalid' : ''} ${styles['scaling-factor-input']}`}
                          type="text"
                          name={`modifiers[${index}].scalingFactor`}
                          defaultValue={modifier.scalingFactor}
                          onInput={(e) => setScalingFactor(index, (e.target as HTMLInputElement).value)}
                          onBlur={(formik.handleBlur)}
                        />
                        <button
                          className={styles['remove-modifier-button']}
                          onClick={() => removeModifier(index)}
                        >X
                        </button>
                      </div>
                    </label>
                    { modifierHasValidationErrors(index)
                      && <div className={'validation-error row justify-end'}>
                        {(formik.errors.modifiers![index] as { scalingFactor: string }).scalingFactor}
                      </div>
                    }
                  </React.Fragment>
                ))
              }
            </div>
        }
      </div>
    </ModalBase>
  )
}
