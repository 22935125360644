import 'leaflet/dist/leaflet.css'
import { BrowserRouter } from 'react-router-dom'
import { Toaster } from 'react-hot-toast'
import { ServiceProvider } from "./services/serviceProvider"
import { AuthenticationGuard } from "components/authenticationGuard/authenticationGuard"
import { AppRouter } from "components/appRouter/appRouter"
import { AppEditionPicker } from "components/appEditionPicker/appEditionPicker"
import { ModalRoot } from "services/modal/modalRoot"

function App() {
  return (
    <BrowserRouter>
      <ServiceProvider>
        <ModalRoot />
        <AuthenticationGuard>
          <Toaster containerStyle={{ zIndex: 99999 } } />
          <AppRouter />
          <AppEditionPicker />
        </AuthenticationGuard>
      </ServiceProvider>
    </BrowserRouter>
  )
}

export default App
