import "./primaryButton.scoped.scss"
import { FC } from "react"

export interface PrimaryButtonProps {
  text?: string
  onClick?: () => void
  submit?: boolean
}

export const PrimaryButton: FC<PrimaryButtonProps> = (props) => {
  return (
    <button
      className="button primary"
      onClick={props.onClick}
      type={props.submit ? "submit" : "button"}
    >
      { props.text !== undefined? props.text : props.children }
    </button>
  )
}
