import {
  ChangeEvent,
  FocusEvent,
  FC,
} from "react"
import './selectField.scoped.scss'

export interface SelectFieldProps {
  label: string | React.ReactNode
  name?: string
  value?: string | number
  isInvalid?: boolean
  errors?: string
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void
  onBlur?: (event: FocusEvent) => void
  options?: { name: string, value: string | number }[]
  disabled?: boolean
}

export const SelectField = (props: SelectFieldProps) => {
  return (
    <label className="select-field column">
      <span className={'form-label'}>{props.label}</span>
      <select
        name={props.name}
        value={props.value  ?? ''}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={`input ${props.isInvalid ? 'invalid' : ''}`}
        disabled={props.disabled}
      >
        <option disabled value={''}>Velg ...</option>
        { props.options?.map((option, index) => (
          <option value={option.value} key={index}>
            {option.name}
          </option>
        )) }
      </select>
      {props.isInvalid && (
        <div className={'validation-error column gap1 mt1'}>
          {props.errors}
        </div>
      )}
    </label>
  )
}

