import * as yup from "yup"
import { UtmZone } from "services/sicalcApi/sharedEntities/utmCoordinates"
import { eastingValidator, northingValidator } from "utils/validators/coordinateValidators"

export interface UtmCalculationPointFormDataModel {
  calculationPoints: UtmCalculationPoint[]
  zone?: UtmZone
}

export interface UtmCalculationPoint {
  name?: string
  altitude?: 1.5 | 4
  // zone: UtmZone
  // hemisphere: UtmHemisphere
  easting: number
  northing: number
}

export const utmCalculationPointValidationSchema = yup.object().shape({
  zone: yup.number()
    .required("Påkrevd"),
  calculationPoints: yup.array()
    .of(yup.object().shape({
      name: yup.string()
        .optional()
        .nullable()
        .max(70, "Maks 70 tegn"),
      easting: eastingValidator,
      northing: northingValidator,
    })),
})
