import React, { FC } from "react"
import './calculationTaskPageTemplate.scoped.scss'

export interface CalculationTaskPageTemplateProps {
  sidebar: React.ReactNode
}

export const CalculationTaskPageTemplate: FC<CalculationTaskPageTemplateProps> = (props) => {
  return (
    <div className="calculation-task-page-template row">
      <div className="sidebar">
        {props.sidebar}
      </div>
      <div className="main column">
        {props.children}
      </div>
    </div>
  )
}

