import {
  CalculationTaskResponse_Scenario,
  CalculationTaskResponse_WorstCaseScenarioSettings,
} from "services/sicalcApi/responses/calculationTaskResponse"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { ScenarioType } from "services/sicalcApi/sharedEntities/scenarioType"

export function getConstituentScenarioLabelsForWorstCaseScenario(
  worstCaseScenario: CalculationTaskResponse_WorstCaseScenarioSettings,
  scenarios: CalculationTaskResponse_Scenario[],
  customScenarios: CustomScenarioResponse[],
) {
  let firstScenarioName: string | undefined
  let firstScenarioTargetYear: number | undefined
  if (worstCaseScenario.firstScenarioType === ScenarioType.Scenario) {
    const firstScenario = scenarios.find(s => s.id === worstCaseScenario.firstScenarioId)
    if (!firstScenario) {
      throw new Error(`Worst-case member scenario not found`)
    }
    firstScenarioName = firstScenario.name
    firstScenarioTargetYear = firstScenario.targetYear
  }
  if (worstCaseScenario.firstScenarioType === ScenarioType.CustomScenario) {
    const firstScenario = customScenarios.find(s => s.id === worstCaseScenario.firstScenarioId)
    if (!firstScenario) {
      throw new Error(`Worst-case member scenario not found`)
    }
    firstScenarioName = firstScenario.name
    firstScenarioTargetYear = firstScenario.targetYear
  }

  let secondScenarioName: string | undefined
  let secondScenarioTargetYear: number | undefined
  if (worstCaseScenario.secondScenarioType === ScenarioType.Scenario) {
    const secondScenario = scenarios.find(s => s.id === worstCaseScenario.secondScenarioId)
    if (!secondScenario) {
      throw new Error(`Worst-case member scenario not found`)
    }
    secondScenarioName = secondScenario.name
    secondScenarioTargetYear = secondScenario.targetYear
  }
  if (worstCaseScenario.secondScenarioType === ScenarioType.CustomScenario) {
    const secondScenario = customScenarios.find(s => s.id === worstCaseScenario.secondScenarioId)
    if (!secondScenario) {
      throw new Error(`Worst-case member scenario not found`)
    }
    secondScenarioName = secondScenario.name
    secondScenarioTargetYear = secondScenario.targetYear
  }

  return {
    firstScenario: {
      name: firstScenarioName,
      targetYear: firstScenarioTargetYear,
      label: `${firstScenarioTargetYear} – ${firstScenarioName}`,
    },
    secondScenario: {
      name: secondScenarioName,
      targetYear: secondScenarioTargetYear,
      label: `${secondScenarioTargetYear} – ${secondScenarioName}`,
    },
  }
}
