export interface NtmCoordinates {
  zone: NtmZone
  northing: number
  easting: number
}

export enum NtmZone {
  Zone5 = 5,
  Zone6 = 6,
  Zone7 = 7,
  Zone8 = 8,
  Zone9 = 9,
  Zone10 = 10,
  Zone11 = 11,
  Zone12 = 12,
  Zone13 = 13,
  Zone14 = 14,
  Zone15 = 15,
  Zone16 = 16,
  Zone17 = 17,
  Zone18 = 18,
  Zone19 = 19,
  Zone20 = 20,
  Zone21 = 21,
  Zone22 = 22,
  Zone23 = 23,
  Zone24 = 24,
  Zone25 = 25,
  Zone26 = 26,
  Zone27 = 27,
  Zone28 = 28,
  Zone29 = 29,
  Zone30 = 30
}
