import 'components/common/links/pageLink/pageLink.scoped.scss'
import { FC } from "react"

export interface PageLinkProps {

}

export const PageLink: FC<PageLinkProps> = (props) => {
  return (
    <span>
      <span className="page-link">
        {props.children}
      </span>
    </span>

  )
}

