import { FC } from "react"
import './calculationTaskList.scoped.scss'

export interface CalculationTaskListProps {

}

export const CalculationTaskList: FC<CalculationTaskListProps> = (props) => {
  return (
    <div className="calculation-task-list column">
      <h2 className="heading">Beregninger</h2>
      <ul className="calculation-task-list-items">
        <div className="table-head">
          <span className="table-head-cell">Navn</span>
          <span className="table-head-cell">Sist endret</span>
          <span className="table-head-cell">Opprettet av</span>
          <span className="table-head-cell">Status</span>
          <span className="table-head-cell">Handlinger</span>
          <span className="table-head-cell"></span>
        </div>
        {props.children}
      </ul>
    </div>
  )
}

