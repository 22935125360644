import { ChangePasswordRequest } from "../requests/changePasswordRequest"
import { CreateUserRequest } from "../requests/createUserRequest"
import { SignInRequest } from "../requests/signInRequest"
import { AuthenticationResponse } from "../responses/authenticationResponse"
import { AxiosInstance } from "axios"

export class AuthenticationApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }
  public async signIn(request: SignInRequest) {
    const url = `/api/v1/identity/sign-in`
    const response = await this.sicalcClient.post<AuthenticationResponse>(url, request)
    return response.data
  }

  public async createUser(request: CreateUserRequest) {
    const url = `/api/v1/identity/register`
    const response = await this.sicalcClient.post<AuthenticationResponse>(url, request)
    return response.data
  }

  public async changePassword(request: ChangePasswordRequest) {
    const url = `/api/v1/identity/change-password`
    await this.sicalcClient.post(url, request)
  }
}

