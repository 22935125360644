import { useLocation, useNavigate } from "react-router-dom"
import { SignInPageTemplate } from "components/signIn/signInPageTemplate/signInPageTemplate"
import { SignInForm } from "components/signIn/signInForm/signInForm"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { useAuthService } from "services/auth/authService"
import { useState } from "react"
import { AxiosError } from "axios"
import { User } from "services/auth/models/user"

export interface SignInPageProps {

}

export const SignInPage = (props: SignInPageProps) => {
  const [ authenticationErrorMessage, setAuthenticationErrorMessage ] = useState<string>()

  const location = useLocation()
  const navigate = useNavigate()
  const authService = useAuthService()
  const { authenticationApi } = useSicalcApiService()

  const onSignIn = (user: User) => {
    authService.setCurrentUser(user)
    const signInRedirect = (location.state as any)?.signInRedirect
    navigate(signInRedirect ?? "/", { replace: true })
  }

  const signIn = async (credentials: { username: string, password: string }) => {
    try {
      const response = await authenticationApi.signIn(credentials)
      onSignIn({ token: response.token, expirationDate: response.expirationDate })
    } catch (error) {
      if (error instanceof AxiosError) {
        const axiosError = error as AxiosError
        if (axiosError.response?.status === 401) {
          setAuthenticationErrorMessage("Feil brukernavn eller passord")
        } else {
          setAuthenticationErrorMessage("")
        }
      }
    }
  }

  return (
    <SignInPageTemplate>
      <SignInForm
        onSubmit={signIn}
        authenticationErrorMessage={authenticationErrorMessage}
      />
    </SignInPageTemplate>
  )
}
