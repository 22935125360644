/* eslint-disable react-hooks/exhaustive-deps */
/*
  Adapted from
  https://gist.github.com/SPodjasek/c5354da2e897daa14654674ab21c9b72#file-formik-autosave-tsx-L39
 */
import { useFormikContext } from 'formik'
import debounce from 'lodash/debounce'
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react'
import isEqual from 'react-fast-compare'

type FormFieldAutoSaverStatus = 'changed' | 'saved' | undefined;

export interface FormFieldAutoSaverProps {
  /**
   * Number of milliseconds to wait after last change before submitting the form
   */
  debounceMs?: number
  onSave: (values: any) => void
}

export interface FormFieldAutoSaverState {
  status?: FormFieldAutoSaverStatus
  values?: any
}

export const FormFieldAutoSaver = ({ debounceMs = 250, onSave }: FormFieldAutoSaverProps) => {
  const formik = useFormikContext()
  const [{ values }, setStatus] = useState<FormFieldAutoSaverState>({
    status: undefined,
    values: formik.values,
  })
  const debouncedSubmit = useCallback(
    debounce(async (values) => {
      onSave(values)
      setStatus({
        status: 'saved',
        values,
      })
    }, debounceMs),
    [ formik.submitForm, debounceMs ],
  )

  useEffect(() => {
    if (
      formik.isValid
      && (formik.dirty || !isEqual(formik.values, values))
      && !formik.isSubmitting
    ) {
      setStatus({
        status: 'changed',
        values,
      })
      debouncedSubmit(formik.values)
    }
    return debouncedSubmit.cancel
  }, [ debouncedSubmit, formik.values ])

  return <></>
}
