import { FC, ReactNode } from "react"
import 'components/common/modals/modalBase/modalBase.scoped.scss'
import { ModalBackdrop } from "components/common/modals/modalBackdrop/modalBackdrop"
import { useEscapeKeyHandler } from "hooks/useEscapeKeyHandler"
import { FocusTrap } from "components/common/focusTrap"
import { CloseButton } from "components/common/buttons/closeButton/closeButton"

export interface ModalBaseProps {
  onDismiss: () => void
  dismissOnClickOutside?: boolean
  actions?: ReactNode
  heading?: string
}

export const ModalBase: FC<ModalBaseProps> = ({ dismissOnClickOutside = true, ...props }) => {
  useEscapeKeyHandler(() => {
    if (props.onDismiss !== undefined) {
      props.onDismiss()
    }
  })
  return (
    <ModalBackdrop onClick={() => dismissOnClickOutside && props.onDismiss()}>
      <div className="modal-content column">
        <FocusTrap>
          <div className="modal-header row align-center space-between">
            <h2 className="modal-heading">{props.heading}</h2>
            <CloseButton onClick={props.onDismiss}></CloseButton>
          </div>
          <div className="modal-body">
            {props.children}
          </div>
          <div className="modal-footer row justify-end gap1">
            {props.actions}
          </div>
        </FocusTrap>
      </div>
    </ModalBackdrop>
  )
}
