import React, { FC } from "react"
import './calculationTaskListItem.scoped.scss'
import { ProjectResponse_CalculationTask } from "services/sicalcApi/responses/projectResponse"
import { Link } from "react-router-dom"
import { PageLink } from "../../common/links/pageLink/pageLink"
import {
  ProjectPageCalculationTaskStatus,
} from "components/project/projectPageCalculationTaskStatus/projectPageCalculationTaskStatus"
import {
  ProjectPageCalculationTaskActions,
  ProjectPageCalculationTaskActionsProps,
} from "../projectPageCalculationTaskActions/projectPageCalculationTaskActions"

export interface CalculationTaskListItemProps {
  calculationTask: ProjectResponse_CalculationTask
  actionProps: ProjectPageCalculationTaskActionsProps
}

export const CalculationTaskListItem: FC<CalculationTaskListItemProps> = (props) => {

  const formattedDate = new Date(props.calculationTask.lastChangedDate)
    .toLocaleString('nb-NO', { dateStyle: "short" })

  return (
    <li className="calculation-task-list-item">
      <span className="calculation-task-name cell">
        <Link
          to={"../calculation-tasks/" + props.calculationTask.id}
          className=""
        >
          <PageLink>
            {props.calculationTask.name}
          </PageLink>
        </Link>
      </span>
      <span className="date cell">{formattedDate}</span>
      <span className="created-by cell">{props.calculationTask.creatorName}</span>
      <span className="status cell">
        <ProjectPageCalculationTaskStatus
          status={props.calculationTask.status}
          progressPercent={props.calculationTask.progressPercent}
          progressStatusMessage={props.calculationTask.progressStatusMessage}
        />
      </span>
      <span className="actions cell">
        <ProjectPageCalculationTaskActions {...props.actionProps} />
      </span>
    </li>
  )
}

