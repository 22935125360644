import { UtmZone } from "services/sicalcApi/sharedEntities/utmCoordinates"

export enum AppEdition {
  consultantTest = "consultantTest",
  consultant = "consultant",
  avinor = "avinor",
  forsvarsbygg = "forsvarsbygg",
  isavia = "isavia",
  isaviaTest = "isaviaTest",
}

export interface AppEditionSettings {
  coordinates: {
    canUseUtmCoordinates: boolean
    canUseNtmCoordinates: boolean
    accessibleUtmZones: UtmZone[]
  }
  map: {
    tileLayerUrl: string
    attributionHtml: string
  }
  projectListPageHeading: string
  canCreateProjects: boolean
  canCreateCustomScenarios: boolean
  canSelectNoiseSourceWhenCreatingCalculationTasks: boolean
  canCreateBuildings: boolean
}
