import { AreaSelectionMode, GridResolution } from "services/sicalcApi/sharedEntities/sicalcParameters"
import {
  UpdateCalculationTaskRequest_CalculationArea,
  UpdateCalculationTaskRequest_EditCalculationPointDialogOptions,
  UpdateCalculationTaskRequest_ReportSettings,
  UpdateCalculationTaskRequest_WorstCaseScenarioSettings,
} from "services/sicalcApi/requests/updateCalculationTaskRequest"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import * as yup from "yup"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"

export interface CalculationPoint {
  name?: string
  latitude: number
  longitude: number
  altitude?: 1.5 | 4
}

export interface FormDataModel {
  name?: string
  noiseSourceId: string
  includedScenarioIds: string[]
  calculationPoints: CalculationPoint[]
  areaSelectionMode: AreaSelectionMode
  calculationArea?: UpdateCalculationTaskRequest_CalculationArea
  calculationAltitude?: number
  calculationResolution?: GridResolution
  editCalculationPointDialogOptions: UpdateCalculationTaskRequest_EditCalculationPointDialogOptions
  reportSettings: UpdateCalculationTaskRequest_ReportSettings
  outputCoordinateSystem: CoordinateSystem
  outputCoordinateSystemZone: number | null
  worstCaseScenario: UpdateCalculationTaskRequest_WorstCaseScenarioSettings | null
}

export const generateValidationSchema = (customScenarios: CustomScenarioResponse[] | undefined) =>
  yup.object().shape({
    noiseSourceId: yup.string()
      .required("Påkrevd"),
    includedScenarioIds: yup.array()
      .test("mustHaveAtLeastOneScenarioOrCustomScenario", "Beregningen må ha med minst ett scenario",
        async includedScenarioIds => {
          if (includedScenarioIds && includedScenarioIds.length > 0 ) {
            return true
          }
          return customScenarios !== undefined && customScenarios.length > 0
        }),
    areaSelectionMode: yup.string(),
    calculationArea: yup.object()
      .when("areaSelectionMode", {
        is: (areaSelectionMode: any, schema: any) => {
          return areaSelectionMode === AreaSelectionMode.selectedArea
        },
        then: yup.object().required("Beregningsområde er ikke valgt"),
      }),
  })
