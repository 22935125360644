import './pageHeading.scoped.scss'
import React, { FC } from "react"

export interface PageHeadingProps {

}

export const PageHeading: FC<PageHeadingProps> = (props) => {
  return (
    <h1 className="page-heading">
      { props.children }
    </h1>
  )
}
