import { FC } from "react"
import { SicalcApiServiceProvider } from "./sicalcApi/sicalcApiService"
import { AuthServiceProvider } from "services/auth/authService"
import { SignalrServiceProvider } from "services/signalrService/signalrService"
import { AppEditionServiceProvider } from "services/appEdition/appEditionService"
import { ModalServiceProvider } from "services/modal/modalService"
import { MapServiceProvider } from "services/mapService/mapService"

export const ServiceProvider: FC = ({ children }) => {
  return (
    <>
      <AuthServiceProvider>
        <AppEditionServiceProvider>
          <SicalcApiServiceProvider>
            <SignalrServiceProvider>
              <ModalServiceProvider>
                <MapServiceProvider>
                  {children}
                </MapServiceProvider>
              </ModalServiceProvider>
            </SignalrServiceProvider>
          </SicalcApiServiceProvider>
        </AppEditionServiceProvider>
      </AuthServiceProvider>
    </>
  )
}
