import React from "react"
import './appEditionPicker.scoped.scss'
import { AppEdition } from "services/appEdition/models/appEdition"
import { useAppEditionService } from "services/appEdition/appEditionService"
import { useAuthService } from "services/auth/authService"
import { SicalcUserRole } from "services/auth/models/sicalcUserRole"

export interface AppEditionPickerProps {

}

export const AppEditionPicker = (props: AppEditionPickerProps) => {
  const { currentAppEdition, setCurrentAppEdition } = useAppEditionService()
  const { userHasRole } = useAuthService()

  if (!userHasRole(SicalcUserRole.Administrator)) {
    return <></>
  }

  (window as any).currentAppEdition = currentAppEdition

  return (
    <div className={"app-edition-picker"}>
      <div className={"form-label"}>App-utgave</div>
      <select
        name="app-edition"
        className={"input"}
        value={currentAppEdition}
        defaultValue={""}
        onChange={ev => setCurrentAppEdition(ev.target.value as AppEdition)}
      >
        <option disabled value={""}>Velg ...</option>
        <option value={AppEdition.avinor}>Avinor</option>
        <option value={AppEdition.forsvarsbygg}>Forsvarsbygg</option>
        <option value={AppEdition.isavia}>Isavia</option>
        <option value={AppEdition.isaviaTest}>Isavia (test)</option>
        <option value={AppEdition.consultant}>Konsulent</option>
        <option value={AppEdition.consultantTest}>Konsulent (test)</option>
      </select>
    </div>
  )
}
