import './calculationTaskLoadingView.scoped.scss'
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { PageHeader } from "components/common/pageHeader/pageHeader"
import {
  CalculationTaskPageTemplate,
} from "components/calculationTask/calculationTaskPageTemplate/calculationTaskPageTemplate"
import {
  CalculationTaskPageSidebar,
} from "components/calculationTask/calculationTaskPageSidebar/calculationTaskPageSidebar"

export interface CalculationTaskLoadingViewProps {

}

export const CalculationTaskLoadingView = (props: CalculationTaskLoadingViewProps) => {
  return (
    <BasePageLayout
      header={
        <PageHeader></PageHeader>
      }
    >
      <CalculationTaskPageTemplate
        sidebar={
          <CalculationTaskPageSidebar
          >
            <span>Laster ...</span>
          </CalculationTaskPageSidebar>
        }
      >
      </CalculationTaskPageTemplate>
    </BasePageLayout>
  )
}

