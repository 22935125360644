import { FC } from "react"
import './clearButton.scoped.scss'

export interface ClearButtonProps {
  text?: string
  iconName?: string
  onClick?: () => void
  disabled?: boolean
  ariaLabel?: string
}

export const ClearButton: FC<ClearButtonProps> = (props) => {
  return (
    <button
      className="button clear no-wrap"
      onClick={props.onClick}
      type="button"
      disabled={props.disabled}
      aria-label={props.ariaLabel}
    >
      {props.iconName && (
        <span className="icon-wrapper">
          <i className={`mdi mdi-${props.iconName}`}></i>
        </span>
      )}
      <span>{props.children ?? props.text}</span>
    </button>
  )
}
