import './projectListContainer.scoped.scss'
import { FC } from "react"

export interface ProjectListContainerProps {

}

export const ProjectListContainer: FC<ProjectListContainerProps> = ({ children }) => {
  return (
    <ul className="project-list-container column">
      {children}
    </ul>
  )
}

