
export interface UtmCoordinates {
  zone: UtmZone
  hemisphere: UtmHemisphere
  northing: number
  easting: number
}

export enum UtmHemisphere {
  N = "n",
  S = "s"
}

export enum UtmZone {
  Zone27 = 27,
  Zone28 = 28,
  Zone31 = 31,
  Zone32 = 32,
  Zone33 = 33,
  Zone34 = 34,
  Zone35 = 35
}
