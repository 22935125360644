import { UtmZone } from "services/sicalcApi/sharedEntities/utmCoordinates";
import { AppEdition, AppEditionSettings } from "services/appEdition/models/appEdition";
import getDeepClone from "rfdc";
const deepClone = getDeepClone();

const statKartMapSettings = {
  tileLayerUrl: "https://cache.kartverket.no/v1/wmts/1.0.0/topo/default/webmercator/{z}/{y}/{x}.png",
  attributionHtml: '&copy; <a href="https://kartverket.no" target="_blank">Kartverket</a>'
};

const osmMapSettings = {
  tileLayerUrl: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
  attributionHtml: '&copy; <a href="https://www.openstreetmap.org/copyright" target="_blank">OpenStreetMap</a>'
};

const norwayCoordinateSettings = {
  canUseNtmCoordinates: true,
  canUseUtmCoordinates: true,
  accessibleUtmZones: [UtmZone.Zone31, UtmZone.Zone32, UtmZone.Zone33, UtmZone.Zone34, UtmZone.Zone35]
};

// Note that these defaults are used when no specific app edition can be determined, not just as a common base.
// They should disable anything that can be disabled, and provide the best possible defaults for anything else.
export const defaultAppEditionSettings: AppEditionSettings = {
  coordinates: {
    canUseNtmCoordinates: false,
    canUseUtmCoordinates: false,
    accessibleUtmZones: []
  },
  map: osmMapSettings,
  canCreateBuildings: false,
  canCreateProjects: false,
  canCreateCustomScenarios: false,
  canSelectNoiseSourceWhenCreatingCalculationTasks: false,
  projectListPageHeading: "Prosjekter"
};

export const appEditionToSettingsMap: { [appEdition: string]: AppEditionSettings } = {
  [AppEdition.avinor]: {
    ...defaultAppEditionSettings,
    projectListPageHeading: "Flyplasser",
    coordinates: norwayCoordinateSettings,
    map: statKartMapSettings,
    canCreateCustomScenarios: true
  },
  [AppEdition.forsvarsbygg]: {
    ...defaultAppEditionSettings,
    projectListPageHeading: "Flyplasser",
    coordinates: norwayCoordinateSettings,
    map: statKartMapSettings,
    canCreateCustomScenarios: true
  },
  [AppEdition.consultant]: {
    ...defaultAppEditionSettings,
    coordinates: norwayCoordinateSettings,
    map: statKartMapSettings,
    canCreateProjects: true,
    canCreateBuildings: true,
    canSelectNoiseSourceWhenCreatingCalculationTasks: true
  },
  [AppEdition.isavia]: {
    ...defaultAppEditionSettings,
    coordinates: {
      canUseNtmCoordinates: false,
      canUseUtmCoordinates: true,
      accessibleUtmZones: [UtmZone.Zone27, UtmZone.Zone28]
    },
    map: osmMapSettings,
    projectListPageHeading: "Flyplasser",
    canCreateCustomScenarios: true
  }
};

appEditionToSettingsMap[AppEdition.consultantTest] = deepClone(appEditionToSettingsMap[AppEdition.consultant]);
// Modifications here

appEditionToSettingsMap[AppEdition.isaviaTest] = deepClone(appEditionToSettingsMap[AppEdition.isavia]);
// Modifications here