import './progressGauge.scoped.scss'

export interface ProgressGaugeProps {
  progressPercent: number
}

export const ProgressGauge = ({ progressPercent }: ProgressGaugeProps) => {
  return (
    <div className="progress-gauge">
      <svg
        className="progress-icon"
        viewBox="0 0 100 100"
        style={{ '--progress-percent': progressPercent } as React.CSSProperties}
      >
        <circle className="background" />
        <circle className="indicator" />
      </svg>
      <span className="progress-label no-wrap">{Math.round(progressPercent)} %</span>
    </div>
  )
}
