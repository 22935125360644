import { FC } from "react"
import 'components/common/buttons/dangerButton/dangerButton.scoped.scss'

export interface WarningButtonProps {
  text?: string
  onClick?: () => void
  submit?: boolean
}

export const DangerButton: FC<WarningButtonProps> = (props) => {
  return (
    <button
      className="button danger"
      onClick={props.onClick}
      type={props.submit ? "submit" : "button"}
    >
      { props.children ?? props.text}
    </button>
  )
}
