import './scenarioPickerCustomScenario.scoped.scss'
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import { ToolTip } from "components/common/toolTip/toolTip"
import { CheckboxInput } from "components/common/formFields/CheckboxInput/CheckboxInput"

export interface ScenarioPickerCustomScenarioProps {
  name: string
  onClickDelete: () => void
}

export const ScenarioPickerCustomScenario = (props: ScenarioPickerCustomScenarioProps) => {
  return (
    <span className="custom-scenario row space-between align-center">
      <div className="row align-center">
        <CheckboxInput label={""} value={true} onChange={() => {}} disabled />
        <span>{props.name}</span>
      </div>
      <ToolTip content="Slett" defaultPosition="top-end">
        <ClearButton iconName="delete" onClick={props.onClickDelete} ariaLabel="Slett"></ClearButton>
      </ToolTip>
    </span>

  )
}

