import {
  FC,
} from "react"
import './createProjectModal.scoped.scss'
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { ModalProps } from "services/modal/modalService"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import * as yup from "yup"
import { useFormik } from "formik"
import { TextField } from "components/common/formFields/textField/textField"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { toast } from "react-hot-toast"
import { TextAreaField } from "components/common/formFields/textAreaField/textAreaField"
import { NoiseSourceInput } from "components/common/formFields/noiseSourceInput/noiseSourceInput"

export interface CreateProjectModalProps extends ModalProps {

}

interface FormDataModel {
  projectName: string
  defaultNoiseSourceId: string
  projectNotes: string
}

const formValidationSchema = yup.object().shape({
  projectName: yup.string()
    .required("Påkrevd")
    .min(1, "Må bestå av minst ett tegn")
    .max(70, "Navnet er for langt (maks 70 tegn)"),
  projectNotes: yup.string()
    .max(1800, "Maks 1800 tegn"),
})

export const CreateProjectModal: FC<CreateProjectModalProps> = (props) => {
  const { projectApi } = useSicalcApiService()

  const handleSubmission = async (values: FormDataModel) => {
    const { projectName, defaultNoiseSourceId, projectNotes } = values
    try {
      const result = await projectApi.create({
        name: projectName.trim(),
        notes: projectNotes.trim(),
        defaultNoiseSourceId,
      })
      props.modalContext.close(result)
    } catch {
      toast.error("Kunne ikke opprette prosjektet")
    }
  }

  const initialValues: FormDataModel = {
    projectName: '',
    defaultNoiseSourceId: '',
    projectNotes: '',
  }

  const form = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: handleSubmission,
  })

  const dismiss = () => {
    props.modalContext.dismiss()
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      heading="Nytt prosjekt"
      actions={
        <>
          <LightButton onClick={dismiss}>Avbryt</LightButton>
          <PrimaryButton onClick={form.handleSubmit}>
            Opprett
          </PrimaryButton>
        </>
      }
    >
      <form className="column gap4">
        <TextField
          label="Prosjektnavn*"
          name="projectName"
          value={form.values.projectName}
          isInvalid={form.touched.projectName === true && form.errors.projectName !== undefined}
          errors={form.errors.projectName}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <TextAreaField
          label="Prosjektinfo"
          name="projectNotes"
          value={form.values.projectNotes}
          isInvalid={form.touched.projectNotes === true && form.errors.projectNotes !== undefined}
          errors={form.errors.projectNotes}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          rows={10}
        />
        <label className="column gap1">
          <span className="form-label">Standard støykilde</span>
          <NoiseSourceInput
            value={form.values.defaultNoiseSourceId}
            onChange={value => form.setFieldValue("defaultNoiseSourceId", value)}
            onBlur={form.handleBlur}
          />
        </label>
      </form>
    </ModalBase>
  )
}

