import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import {
  useCallback,
  useEffect,
  useState,
} from "react"
import { ProjectResponse } from "services/sicalcApi/responses/projectResponse"
import { ProjectListItem } from "components/project/projectListItem/projectListItem"
import { ProjectListContainer } from "components/project/projectListContainer/projectListContainer"
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { ProjectListPageTemplate } from "components/project/projectListPageTemplate/projectListPageTemplate"
import { PageHeading } from "components/common/headings/pageHeading/pageHeading"
import { useModalService } from "services/modal/modalService"
import { CreateProjectModal } from "components/project/createProjectModal/createProjectModal"
import { BigActionButton } from "components/common/buttons/bigActionButton/bigActionButton"
import { AppEditionFilter } from "components/appEditionFilter/appEditionFilter"
import { useAppEditionService } from "services/appEdition/appEditionService"

export function ProjectsListPage() {
  const { projectApi } = useSicalcApiService()
  const [ projects, setProjects ] = useState<ProjectResponse[]>([])
  const { showModal } = useModalService()
  const appEditionService = useAppEditionService()

  const refreshProjects = useCallback(async () => {
    const response = await projectApi.getAll()
    setProjects(response.projects)
  }, [projectApi])


  useEffect(() => {
    refreshProjects()
  }, [refreshProjects])

  const createProject = async () => {
    const result = await showModal(context =>
      <CreateProjectModal
        modalContext={context}
      />)
    if (result !== undefined) {
      await refreshProjects()
    }
  }

  return (
    <BasePageLayout>
      <ProjectListPageTemplate
        heading={
          <>
            <PageHeading>{appEditionService.settings.projectListPageHeading}</PageHeading>
            <AppEditionFilter availableWhen={s => s.canCreateProjects}>
              <BigActionButton onClick={createProject}>Nytt prosjekt</BigActionButton>
            </AppEditionFilter>
          </>
        }
      >
        <ProjectListContainer>
          {projects.map((project, index) => (
            <ProjectListItem project={project} key={index} />
          ))}
        </ProjectListContainer>
      </ProjectListPageTemplate>
    </BasePageLayout>
  )
}
