import React from 'react'
import { createRoot } from 'react-dom/client'
import "./styles/main.scss"
import App from './App'
import reportWebVitals from './reportWebVitals'

const container = document.getElementById('root') as Element
createRoot(container)
  .render(
    <React.StrictMode>
      <App />
    </React.StrictMode>,
  )

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
