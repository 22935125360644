import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import { User } from "services/auth/models/user"
import { SicalcUserRole } from "services/auth/models/sicalcUserRole"

export interface AuthService {
  currentUser: User | undefined
  userIsAuthenticated: boolean
  userHasRole: (role: SicalcUserRole) => boolean
  currentUserRoles: SicalcUserRole[]
  setCurrentUser: (user: User) => void
  signOut: () => void
}

const localStorageKey = "Sicalc_CurrentUser"

const AuthServiceContext = createContext<AuthService | undefined>(undefined)

export const AuthServiceProvider: FC = ({ children }) => {
  const localStorageUserString = localStorage.getItem(localStorageKey)
  const localStorageUser = localStorageUserString
    ? JSON.parse(localStorageUserString) as User
    : undefined

  const [ currentUser, setCurrentUser ] = useState<User | undefined>(localStorageUser)

  // Give the isAuthenticated property a nice getter, which
  // can't be done directly inside a functional component
  const userIsAuthenticatedGetterWrapper = {
    get userIsAuthenticated() {
      return currentUser !== undefined
    },
  }

  // Give the currentUserRoles property a nice getter, which
  // can't be done directly inside a functional component
  const currentUserRolesGetterWrapper = {
    get currentUserRoles() {
      const token = currentUser?.token
      if (!token) {
        return []
      }

      const tokenClaims = JSON.parse(window.atob(token.split(".")[1]))

      if (Array.isArray(tokenClaims.role)) {
        return tokenClaims.role
      }

      if (typeof tokenClaims.role !== 'string') {
        return []
      }

      return [tokenClaims.role]
    },
  }

  const signOut = () => {
    setCurrentUser(undefined)
  }

  const userHasRole = (role: SicalcUserRole) => {
    const token = currentUser?.token
    if (!token) {
      return false
    }

    const tokenClaims = JSON.parse(window.atob(token.split(".")[1]))

    if (Array.isArray(tokenClaims.role)) {
      return tokenClaims.role.includes(role)
    } else {
      return tokenClaims.role === role
    }
  }

  useEffect(() => {
    if (currentUser !== undefined) {
      localStorage.setItem(localStorageKey, JSON.stringify(currentUser))
    } else {
      localStorage.removeItem(localStorageKey)
    }
  }, [ currentUser ])

  return (
    <AuthServiceContext.Provider
      value={{
        currentUser,
        userIsAuthenticated: userIsAuthenticatedGetterWrapper.userIsAuthenticated,
        currentUserRoles: currentUserRolesGetterWrapper.currentUserRoles,
        userHasRole,
        setCurrentUser,
        signOut,
      }}
    >
      {children}
    </AuthServiceContext.Provider>
  )
}

export const useAuthService = () => {
  const context = useContext<AuthService | undefined>(AuthServiceContext)
  if (!context) {
    const serviceName = Object.keys({ AuthServiceContext })[0]
    throw new Error(serviceName + " was not provided. "
      + "Make sure the component is a child of the required service provider")
  }
  return context
}
