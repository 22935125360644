import './closeButton.scoped.scss'

export interface CloseButtonProps {
  onClick?: () => void
}

export const CloseButton = (props: CloseButtonProps) => {
  return (
    <button className="button close-button" onClick={props.onClick}>
      <i className={`mdi mdi-close`}></i>
    </button>
  )
}
