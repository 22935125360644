import './calculationTaskInProgressView.scoped.scss'
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { PageHeader } from "components/common/pageHeader/pageHeader"
import { NavbarPageHeading } from "components/calculationTask/navbarPageHeading/navbarPageHeading"
import {
  CalculationTaskPageTemplate,
} from "components/calculationTask/calculationTaskPageTemplate/calculationTaskPageTemplate"
import {
  CalculationTaskPageSidebar,
} from "components/calculationTask/calculationTaskPageSidebar/calculationTaskPageSidebar"
import { LatLongPoint, MapPane } from "components/calculationTask/mapPane/mapPane"
import { CalculationTaskResponse } from "services/sicalcApi/responses/calculationTaskResponse"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { ProgressGauge } from "components/common/progressGauge/progressGauge"

import { CalculationTaskBreadCrumbs } from "components/calculationTask/calculationTaskBreadCrumbs"
import { BigActionButton } from "components/common/buttons/bigActionButton/bigActionButton"

export interface CalculationTaskInProgressViewProps {
  calculationTask: CalculationTaskResponse
  onCalculationCanceled: () => void
}

export const CalculationTaskInProgressView = (props: CalculationTaskInProgressViewProps) => {
  const { calculationTaskApi } = useSicalcApiService()

  const noiseSourceLocation: LatLongPoint = {
    latitude: props.calculationTask.noiseSource!.latitude,
    longitude: props.calculationTask.noiseSource!.longitude,
  }

  const cancelCalculationTask = async () => {
    await calculationTaskApi.cancel(props.calculationTask.id)
    props.onCalculationCanceled()
  }

  return (
    <BasePageLayout
      header={
        <PageHeader>
          <NavbarPageHeading>{props.calculationTask.name}</NavbarPageHeading>
        </PageHeader>
      }
    >
      <CalculationTaskPageTemplate
        sidebar={
          <CalculationTaskPageSidebar
            breadcrumbs={(<CalculationTaskBreadCrumbs calculationTask={props.calculationTask} />)}
          >
            <div className="sidebar-content column align-center gap4">
              <h2 className="sidebar-heading">Beregner</h2>
              <div className="column align-center gap4">
                <div className="progress-gauge-wrapper">
                  <ProgressGauge progressPercent={props.calculationTask.progressPercent} />
                </div>
                <span>{props.calculationTask.progressStatusMessage}</span>
              </div>
              <BigActionButton onClick={cancelCalculationTask}>
                Avbryt
              </BigActionButton>
              <span className="text-lighter italic">Beregningen vil foregå i bakgrunnen</span>
            </div>
          </CalculationTaskPageSidebar>
        }
      >
        <MapPane
          mapLocation={noiseSourceLocation}
        />
      </CalculationTaskPageTemplate>
    </BasePageLayout>
  )
}

