import { AxiosInstance } from "axios"
import {
  GeographicToUtmCoordinateConversionRequest,
} from "services/sicalcApi/requests/geographicToUtmCoordinateConversionRequest"
import {
  GeographicToUtmCoordinateConversionResponse,
} from "services/sicalcApi/responses/geographicToUtmCoordinateConversionResponse"
import {
  UtmToGeographicCoordinateConversionRequest,
} from "services/sicalcApi/requests/utmToGeographicCoordinateConversionRequest"
import {
  UtmToGeographicCoordinateConversionResponse,
} from "services/sicalcApi/responses/utmToGeographicCoordinateConversionResponse"
import {
  GeographicToNtmCoordinateConversionResponse,
} from "services/sicalcApi/responses/geographicToNtmCoordinateConversionResponse"
import {
  GeographicToNtmCoordinateConversionRequest,
} from "services/sicalcApi/requests/geographicToNtmCoordinateConversionRequest"
import {
  NtmToGeographicCoordinateConversionResponse,
} from "services/sicalcApi/responses/ntmToGeographicCoordinateConversionResponse"
import {
  NtmToGeographicCoordinateConversionRequest,
} from "services/sicalcApi/requests/ntmToGeographicCoordinateConversionRequest"

export class CoordinateConversionApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async convertGeographicToUtm(request: GeographicToUtmCoordinateConversionRequest) {
    const url = `/api/v1/coordinate-conversion/geographic-to-utm`
    const response = await this.sicalcClient
      .post<GeographicToUtmCoordinateConversionResponse>(url, request)
    return response.data
  }

  public async convertGeographicToNtm(request: GeographicToNtmCoordinateConversionRequest) {
    const url = `/api/v1/coordinate-conversion/geographic-to-ntm`
    const response = await this.sicalcClient
      .post<GeographicToNtmCoordinateConversionResponse>(url, request)
    return response.data
  }

  public async convertUtmToGeographic(request: UtmToGeographicCoordinateConversionRequest) {
    const url = `/api/v1/coordinate-conversion/utm-to-geographic`
    const response = await this.sicalcClient
      .post<UtmToGeographicCoordinateConversionResponse>(url, request)
    return response.data
  }

  public async convertNtmToGeographic(request: NtmToGeographicCoordinateConversionRequest) {
    const url = `/api/v1/coordinate-conversion/ntm-to-geographic`
    const response = await this.sicalcClient
      .post<NtmToGeographicCoordinateConversionResponse>(url, request)
    return response.data
  }
}
