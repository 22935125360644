import './basePageLayout.scoped.scss'
import React, { FC } from "react"
import { PageHeader } from "../../common/pageHeader/pageHeader"

export interface BaseLayoutProps {
  header?: React.ReactNode
}

export const BasePageLayout: FC<BaseLayoutProps> = (props) => {
  return (
    <div className="base-layout column">
      <div className="header">
        {props.header ?? <PageHeader />}
      </div>
      <div className="main">
        {props.children}
      </div>
    </div>
  )
}
