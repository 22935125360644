import { FC } from "react"
import './appEditionFilter.scoped.scss'
import { useAppEditionService } from "services/appEdition/appEditionService"
import { AppEditionSettings } from "services/appEdition/models/appEdition"

export interface AppEditionFilterProps {
  availableWhen: (settings: AppEditionSettings) => boolean
}

export const AppEditionFilter: FC<AppEditionFilterProps> = (props) => {
  const { settings } = useAppEditionService()

  const isAvailable = props.availableWhen(settings)

  if (!isAvailable) {
    return <></>
  }

  return (
    <>{props.children}</>
  )
}

