import './calculationAltitudePicker.scoped.scss'
import { MultiToggle } from "components/common/buttons/multiToggle/multiToggle"

export interface CalculationAltitudePickerProps {
  value: number
  onChange: (value: number) => void
}

export const CalculationAltitudePicker = (props: CalculationAltitudePickerProps) => {
  return (
    <MultiToggle
      value={props.value}
      options={[
        {
          label: "1,5",
          value: 1.5,
        },
        {
          label: "4,0",
          value: 4,
        },
      ]}
      onChange={props.onChange}
    />
  )
}

