import { NoiseSourceListResponse } from "../responses/noiseSourceListResponse"
import { NoiseSourceResponse } from "../responses/noiseSourceResponse"
import { AxiosInstance } from "axios"

export class NoiseSourceApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async getAll() {
    const url = `/api/v1/noise-sources`
    const response = await this.sicalcClient.get<NoiseSourceListResponse>(url)
    return response.data
  }

  public async get(noiseSourceId: string) {
    const url = `/api/v1/noise-sources/${noiseSourceId}`
    const response = await this.sicalcClient.get<NoiseSourceResponse>(url)
    return response.data
  }
}

