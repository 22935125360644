import { TypedEmitter } from "tiny-typed-emitter"

export interface CloseModalEvent {
  /**
   * The result that is returned to the function that opened the modal
   */
  result?: unknown
}

interface ModalContextEvents {
  'close': (result: unknown) => void
}

export class ModalContext {
  private resolve: (result: unknown) => void
  private reject: (result: unknown) => void
  private _emitter: TypedEmitter<ModalContextEvents>

  constructor(
    resolve: (value: unknown) => void,
    reject: (value: unknown) => void,
  ) {
    this.resolve = resolve
    this.reject = reject
    this._emitter = new TypedEmitter<ModalContextEvents>()

    this.onClose((result: unknown) => {
      resolve(result)
    })
  }

  /**
   *
   * @param callback
   * Action to perform when the close event triggers
   */
  onClose(callback: (result: unknown) => void): void {
    this._emitter.addListener('close', callback)
  }

  /**
   * Close the modal, optionally passing a result property
   * @param closeEvent
   */
  close(result: unknown): void {
    this._emitter.emit('close', result)
  }

  /**
   * Close the modal, optionally passing a result property
   * @param closeEvent
   */
  dismiss(): void {
    this._emitter.emit('close', undefined)
  }
}
