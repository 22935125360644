import { FC, ReactNode } from "react"
import './projectPageTemplate.scoped.scss'
import { BigActionButton } from "components/common/buttons/bigActionButton/bigActionButton"

export interface ProjectPageTemplateProps {
  heading: ReactNode
  breadcrumbs?: ReactNode
  onClickCreateCalculationTask: () => void
}

export const ProjectPageTemplate: FC<ProjectPageTemplateProps> = (props) => {
  return (
    <div className="project-page-template column align-center">
      <div className="breadcrumbs row align-start">
        {props.breadcrumbs}
      </div>
      <div className="content-wrapper">
        <div className="heading-wrapper row space-between">
          { props.heading }
          <BigActionButton onClick={props.onClickCreateCalculationTask}>
            Ny beregning
          </BigActionButton>
        </div>
        <div className="main-content">
          { props.children }
        </div>
      </div>
    </div>
  )
}

