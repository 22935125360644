import './sridInput.scoped.scss'
import { SearchableSelectField } from "components/common/formFields/SearchableSelectField/SearchableSelectField"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import { UtmZone } from "services/sicalcApi/sharedEntities/utmCoordinates"
import { useRef } from "react"
import { NtmZone } from "services/sicalcApi/sharedEntities/ntmCoordinates"

interface CoordinateSystemWithZone {
  coordinateSystem: CoordinateSystem
  zone: number | null
}

export interface SridInputProps {
  value: CoordinateSystemWithZone
  onChange: (newValue: CoordinateSystemWithZone) => void
}

// The idea here is that we're mapping between compound values (coordinateSystem, zone) to a string which can be used
// in the select field. The mapping is two-way, so given a value pair, generateLookupKey munges them into a
// string. Then valueMap is used to map the opposite way, from a lookup key to a value pair.
const generateLookupKey = (value: CoordinateSystemWithZone): string => {
  switch (value.coordinateSystem) {
    case CoordinateSystem.Geographic:
      return "Geographic coordinates"
    case CoordinateSystem.Utm:
      return `UTM-${value.zone}`
    case CoordinateSystem.Ntm:
      return `NTM-${value.zone}`
  }
}

const generateLabel = (value: CoordinateSystemWithZone): string => {
  switch (value.coordinateSystem) {
    case CoordinateSystem.Geographic:
      return "Geografiske koordinater"
    case CoordinateSystem.Utm:
      return `UTM, sone ${value.zone}`
    case CoordinateSystem.Ntm:
      return `NTM, sone ${value.zone}`
  }
}

const buildValueMap = () => {
  const valueMap = {} as { [key: string]: {label: string, value: CoordinateSystemWithZone } }

  // Add geographic coordinates
  const geographicCoordinates = {
    coordinateSystem: CoordinateSystem.Geographic,
    zone: null,
  }
  const geographicCoordinatesKey = generateLookupKey(geographicCoordinates)
  valueMap[geographicCoordinatesKey] = {
    label: generateLabel(geographicCoordinates),
    value: geographicCoordinates,
  }

  // Add NTM coordinates
  Object.values(NtmZone)
    .filter(item => !isNaN(Number(item)))
    .map(zone => Number(zone))
    .forEach(zone => {
      const coordinateSystemWithZone = {
        coordinateSystem: CoordinateSystem.Ntm,
        zone,
      }
      const key = generateLookupKey(coordinateSystemWithZone)
      valueMap[key] = {
        label: generateLabel(coordinateSystemWithZone),
        value: coordinateSystemWithZone,
      }
    })

  // Add UTM coordinates
  Object.values(UtmZone)
    .filter(item => !isNaN(Number(item)))
    .map(zone => Number(zone))
    .forEach(zone => {
      const coordinateSystemWithZone = {
        coordinateSystem: CoordinateSystem.Utm,
        zone,
      }
      const key = generateLookupKey(coordinateSystemWithZone)
      // options.push({ label, value: key })
      valueMap[key] = {
        label: generateLabel(coordinateSystemWithZone),
        value: coordinateSystemWithZone,
      }
    })

  return valueMap
}

const buildOptions = (valueMap: { [key: string]: {label: string, value: CoordinateSystemWithZone } }) => {
  return Object.keys(valueMap)
    .map((key) => ({
      label: valueMap[key].label,
      value: key,
    }))
}

export const SridInput = (props: SridInputProps) => {
  const valueMap = useRef(buildValueMap())
  const options = useRef(buildOptions(valueMap.current))

  const getCurrentValue = () => {
    const key = generateLookupKey(props.value)
    return {
      label: valueMap.current[key].label,
      value: key,
    }
  }
  return (
    <label className="column">
      <span className="form-label">Koordinatsystem for resultater</span>
      <SearchableSelectField
        options={options.current}
        value={getCurrentValue()}
        onChange={item => props.onChange(valueMap.current[item as string].value)}
      />
    </label>
  )
}

