import { BreadCrumbs } from "components/common/breadCrumbs/breadCrumbs"
import { ProjectResponse } from "services/sicalcApi/responses/projectResponse"

export interface ProjectPageBreadcrumbsProps {
  project?: ProjectResponse
}

export const ProjectPageBreadcrumbs = (props: ProjectPageBreadcrumbsProps) => {
  const breadcrumbPathSegments = props.project !== undefined
    ? [
      {
        label: "Prosjekter",
        path: "..",
      },
      {
        label: props.project.name,
        path: null,
      },
    ]
    : []

  return (
    <BreadCrumbs segments={breadcrumbPathSegments} />
  )
}
