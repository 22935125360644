import './projectPageCalculationTaskStatus.scoped.scss'
import { CalculationTaskStatus } from "../../../services/sicalcApi/responses/calculationTaskResponse"
import { ProgressGauge } from "../../common/progressGauge/progressGauge"

export interface ProjectPageCalculationTaskStatusProps {
  status: CalculationTaskStatus
  progressPercent: number
  progressStatusMessage?: string
}

export const ProjectPageCalculationTaskStatus = (props: ProjectPageCalculationTaskStatusProps) => {
  const { status, progressPercent } = props
  let statusString = ""

  if (status === CalculationTaskStatus.Draft) {
    statusString = "Under oppsett"
  }
  if (status === CalculationTaskStatus.Running) {
    statusString = `Kjører:`
  }
  if (status === CalculationTaskStatus.Completed) {
    statusString = "Ferdig"
  }
  if (status === CalculationTaskStatus.Failed) {
    statusString = "Feilet"
  }

  const progressGauge = status === CalculationTaskStatus.Running
    ? (
      <span className="progress-gauge-wrapper row align-center justify-center">
        <ProgressGauge progressPercent={props.progressPercent}/>
      </span>
    )
    : undefined

  return (
    <span className="no-wrap row align-center">
      <span className="mr3 ">{statusString}</span>
      <span className="progress-gauge">{progressGauge}</span>
      <span className="ml3 text-lighter">{props.progressStatusMessage}</span>
    </span>
  )
}

