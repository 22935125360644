import { FC, ReactNode } from "react"
import './calculationTaskPageSidebar.scoped.scss'

export interface CalculationTaskPageSidebarProps {
  breadcrumbs?: ReactNode
}


export const CalculationTaskPageSidebar: FC<CalculationTaskPageSidebarProps> = (props) => {
  return (
    <div className="calculation-task-page-sidebar column">
      <div className="breadcrumbs">
        { props.breadcrumbs }
      </div>
      <div className="sidebar-content column">
        {props.children}
      </div>
    </div>
  )
}

