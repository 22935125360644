import { FC } from "react"
import './formSectionHeading.scoped.scss'

export interface FormSectionHeadingProps {

}

export const FormSectionHeading: FC<FormSectionHeadingProps> = (props) => {
  return (
    <h6 className="form-section-heading">
      {props.children}
    </h6>
  )
}

