import React, { FC, useRef } from "react"
import 'components/common/modals/modalBackdrop/modalBackdrop.scoped.scss'

export interface ModalBackdropProps {
  onClick?: () => void
}

export const ModalBackdrop: FC<ModalBackdropProps> = (props) => {
  const backdropRef = useRef<HTMLDivElement>(null)
  const onClick = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (event.target === backdropRef.current && props.onClick !== undefined) {
      props.onClick()
    }
  }
  return (
    <div className="modal-backdrop" onClick={onClick} ref={backdropRef}>
      {props.children}
    </div>
  )
}
