import React, { FC, ReactNode } from "react"
import './breadCrumbs.scoped.scss'
import { Link } from "react-router-dom"
import { PageLink } from "components/common/links/pageLink/pageLink"

export interface BreadCrumbsProps {
  segments: BreadCrumbPathSegment[]
}

export interface BreadCrumbPathSegment {
  label: string
  path: string | null
}

export const BreadCrumbs: FC<BreadCrumbsProps> = (props) => {
  const pathSegments: ReactNode[] = []
  props.segments.forEach((segment, index) => {
    if (index !== 0) {
      pathSegments.push((
        <i className="separator-icon mdi mdi-chevron-right"></i>
      ))
    }
    if (segment.path !== null) {
      pathSegments.push((
        <Link to={segment.path}>
          <PageLink>{segment.label}</PageLink>
        </Link>
      ))
    } else {
      pathSegments.push((
        <span>{segment.label}</span>
      ))
    }
  })

  return (
    <div className="breadcrumbs">
      {React.Children.toArray(pathSegments)}
    </div>
  )
}
