import { FC, ReactNode } from "react"
import './projectListPageTemplate.scoped.scss'

export interface ProjectListPageBodyProps {
  heading: ReactNode
}

export const ProjectListPageTemplate: FC<ProjectListPageBodyProps> = (props) => {
  return (
    <div className="project-list-page-template column align-center">
      <div className="content-wrapper">
        <div className="heading-wrapper row space-between">
          {props.heading}
        </div>
        <div className="main-content">
          {props.children}
        </div>
      </div>
    </div>
  )
}
