import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"

export interface SicalcParameters {
  areaSelectionMode: AreaSelectionMode
  resolution: GridResolution | null
  altitude: number | null
  outputCoordinateSystem: CoordinateSystem
  outputCoordinateSystemZone: number | null
}

export enum AreaSelectionMode {
  everywhere = "everywhere",
  selectedArea = "selectedArea",
  noArea = "noArea",
}

export enum GridResolution {
  G_01 = "g_01",
  G_03 = "g_03",
  G_09 = "g_09",
  G_27 = "g_27",
  G_81 = "g_81"
}

export const GetGridResolutionMeters = (res: GridResolution | undefined): number => {
  switch (res) {
    case GridResolution.G_01:
      return 1
    case GridResolution.G_03:
      return 3
    case GridResolution.G_09:
      return 9
    case GridResolution.G_27:
      return 27
    case GridResolution.G_81:
      return 81
    default:
      return 0
  }
}

