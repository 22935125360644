import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import { AppEdition, AppEditionSettings } from "services/appEdition/models/appEdition"
import { useAuthService } from "services/auth/authService"
import { SicalcUserRole } from "services/auth/models/sicalcUserRole"
import { appEditionToSettingsMap, defaultAppEditionSettings } from "services/appEdition/appEditionToSettingsMap"

export interface AppEditionService {
  currentAppEdition?: AppEdition
  setCurrentAppEdition: (appEdition: AppEdition) => void
  settings: AppEditionSettings
}

const localStorageKey = "Sicalc_AdministratorAppEdition"

const AppEditionServiceContext = createContext<AppEditionService | undefined>(undefined)

export const AppEditionServiceProvider: FC = ({ children }) => {
  const { userHasRole, currentUser } = useAuthService()

  const [ currentAppEdition, _setCurrentAppEdition ] = useState<AppEdition | undefined>()
  const [ currentSettings, setCurrentSettings ] = useState<AppEditionSettings>(defaultAppEditionSettings)

  useEffect(() => {
    if (userHasRole(SicalcUserRole.Consultant)) {
      _setCurrentAppEdition(AppEdition.consultant)
    } else if (userHasRole(SicalcUserRole.AvinorUser)) {
      _setCurrentAppEdition(AppEdition.avinor)
    } else if (userHasRole(SicalcUserRole.ForsvarsbyggUser)) {
      _setCurrentAppEdition(AppEdition.forsvarsbygg)
    } else if (userHasRole(SicalcUserRole.IsaviaUser)) {
      _setCurrentAppEdition(AppEdition.isavia)
    } else {
      if (userHasRole(SicalcUserRole.Administrator)) {
        const localStorageString = localStorage.getItem(localStorageKey)
        const appEdition = localStorageString
          ? Object.values<string>(AppEdition).includes(localStorageString)
            ? localStorageString as AppEdition
            : undefined
          : undefined
        _setCurrentAppEdition(appEdition)
      }
    }
  }, [currentUser, userHasRole])

  useEffect(() => {
    if (currentAppEdition === undefined) {
      return
    }
    const settings = appEditionToSettingsMap[currentAppEdition]
    if (settings === undefined) {
      throw new Error(`No settings defined for app edition '${currentAppEdition}'`)
    }
    setCurrentSettings(settings)
  }, [currentAppEdition])

  const setCurrentAppEdition = (appEdition: AppEdition) => {
    if (userHasRole(SicalcUserRole.Administrator)) {
      _setCurrentAppEdition(appEdition)
      localStorage.setItem(localStorageKey, appEdition)
    }
  }

  return (
    <AppEditionServiceContext.Provider
      value={{
        currentAppEdition,
        setCurrentAppEdition,
        settings: currentSettings,
      }}
    >
      {children}
    </AppEditionServiceContext.Provider>
  )
}

export const useAppEditionService = () => {
  const context = useContext<AppEditionService | undefined>(AppEditionServiceContext)
  if (!context) {
    const serviceName = Object.keys({ AppEditionServiceContext })[0]
    throw new Error(serviceName + " was not provided. "
      + "Make sure the component is a child of the required service provider")
  }
  return context
}
