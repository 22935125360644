import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import * as yup from "yup"
import { latitudeValidator, longitudeValidator } from "utils/validators/coordinateValidators"

export interface CalculationPointFormDataModel {
  calculationPoints: CalculationPoint[]
}

export const calculationPointValidationSchema = yup.object().shape({
  calculationPoints: yup.array()
    .of(yup.object().shape({
      name: yup.string()
        .optional()
        .nullable()
        .max(70, "Maks 70 tegn"),
      latitude: latitudeValidator,
      longitude: longitudeValidator,
    })),
})
