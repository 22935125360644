import { FC } from "react"
import './pageHeaderButton.scoped.scss'

export interface PageHeaderButtonProps {
  text?: string
  iconName?: string
  onClick?: () => void
}

export const PageHeaderButton: FC<PageHeaderButtonProps> = (props) => {
  return (
    <button
      className="button page-header-button"
      onClick={props.onClick}
    >
      <span className="content-wrapper row no-wrap">
        { props.iconName
          && (
            <i className={`mdi mdi-${props.iconName} mr1`}></i>
          )}
        <span className="button-text">
          {props.text ?? props.children}
        </span>
      </span>

    </button>
  )
}

