import React, {
  FC,
  ReactNode,
  useRef,
  useState,
} from "react"
import './dropdown.scoped.scss'
import { useEffectOnClickOutside } from "hooks/useEffectOnClickOutside"

export enum DropdownDirection {
  bottomLeft = "bottom-left",
  bottomRight = "bottom-right",
}

export interface DropdownProps {
  trigger: ReactNode
  onOpen?: () => void
  onClose?: () => void
  direction?: DropdownDirection
}

export const Dropdown: FC<DropdownProps> = (props) => {
  const [isOpen, setIsOpen] = useState(false)
  const dropDownRef = useRef<HTMLDivElement>(null)

  const open = () => {
    setIsOpen(true)
    if (props.onOpen) {
      props.onOpen()
    }
  }

  const close = () => {
    setIsOpen(false)
    if (props.onClose) {
      props.onClose()
    }
  }

  const toggle = () => {
    if (isOpen) {
      close()
    } else {
      open()
    }
  }

  useEffectOnClickOutside(dropDownRef, close)

  return (
    <div className="dropdown" onClick={toggle} ref={dropDownRef}>
      <span className="dropdown-trigger">
        {props.trigger}
      </span>
      {isOpen && (
        <div className={`dropdown-content ${props.direction ?? DropdownDirection.bottomRight}`} >
          {props.children}
        </div>
      )}
    </div>
  )
}

