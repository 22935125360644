export function coerceToStringOrNull(value: string | null | undefined): string | null {
  if (value?.trim() === '') {
    return null
  }

  return value ?? null
}

export function coereceToStringOrUndefined(value: string | null | undefined): string | undefined {
  if (value?.trim() === '') {
    return undefined
  }

  return value ?? undefined
}
