import './tooltipTriggerIcon.scoped.scss'
import React from "react"

export interface TooltipTriggerIconProps {

}

export const TooltipTriggerIcon = (props: TooltipTriggerIconProps) => {
  return (
    <i className="tooltip-trigger-icon mdi mdi-information-slab-circle-outline"/>
  )
}

