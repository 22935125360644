import './coordinateSystemSelectionField.scoped.scss'
import { SelectField, SelectFieldProps } from "components/common/formFields/selectField/selectField"
import { useMemo } from "react"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import { useAppEditionService } from "services/appEdition/appEditionService"

type CoordinateSystemSelectionFieldProps = Omit<SelectFieldProps, 'options'>

export const CoordinateSystemSelectionField = (props: CoordinateSystemSelectionFieldProps) => {
  const appEditionService = useAppEditionService()

  const coordinateSystemOptions = useMemo(() => {
    const options = [] as { name: string, value: CoordinateSystem }[]

    if (appEditionService.settings.coordinates.canUseUtmCoordinates) {
      options.push({
        name: "UTM",
        value: CoordinateSystem.Utm,
      })
    }

    if (appEditionService.settings.coordinates.canUseNtmCoordinates) {
      options.push({
        name: "NTM",
        value: CoordinateSystem.Ntm,
      })
    }

    options.push({
      name: "Geografiske",
      value: CoordinateSystem.Geographic,
    })

    return options
  }, [appEditionService.settings.coordinates])

  return (
    <SelectField options={coordinateSystemOptions} {...props} />
  )
}

