import './projectListItem.scoped.scss'
import { ProjectResponse } from "../../../services/sicalcApi/responses/projectResponse"
import { Link } from "react-router-dom"

export interface ProjectListItemProps {
  project: ProjectResponse
}

export const ProjectListItem = ({ project }: ProjectListItemProps) => {
  const calculationTaskCountUnit = project.calculationTasks.length === 1
    ? "beregning"
    : "beregninger"

  return (
    <li className="project-list-item">
      <Link
        to={project.id}
        className="router-link row space-between align-center"
      >
        <span className="project-name">{project.name}</span>
        <span className="calculation-task-count">
          {project.calculationTasks.length} {calculationTaskCountUnit}
        </span>
        <span className="chevron-icon row justify-end">
          <i className="mdi mdi-chevron-right mdi-24px"></i>
        </span>
      </Link>
    </li>
  )
}
