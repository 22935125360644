import { SicalcParameters } from "services/sicalcApi/sharedEntities/sicalcParameters"
import { NoiseSourceResponse } from "./noiseSourceResponse"
import { CoordinateSystem } from "services/sicalcApi/sharedEntities/coordinateSystem"
import { ScenarioType } from "services/sicalcApi/sharedEntities/scenarioType"

export interface CalculationTaskResponse {
  calculationArea: CalculationTaskResponse_CalculationArea | null
  id: string
  name: string | null
  createdDate: string
  lastChangedDate: string
  noiseSource: NoiseSourceResponse | null
  sicalcParameters: SicalcParameters
  project: CalculationTaskResponse_Project
  status: CalculationTaskStatus
  includedScenarios: CalculationTaskResponse_Scenario[]
  calculationPoints: CalculationTaskResponse_CalculationPoint[]
  editCalculationPointDialogOptions: CalculationTaskResponse_EditCalculationPointDialogOptions
  reportSettings: CalculationTaskResponse_ReportSettings
  worstCaseScenario: CalculationTaskResponse_WorstCaseScenarioSettings | null
  progressPercent: number
  progressStatusMessage: string | null
}

export interface CalculationTaskResponse_CalculationPoint {
  id: string
  name?: string
  longitude: number
  latitude: number
}

export interface CalculationTaskResponse_Scenario {
  id: string
  name: string
  targetYear: number
}

export interface CalculationTaskResponse_Project {
  id: string
  name: string
}

export enum CalculationTaskStatus {
  Draft = "draft",
  Running = "running",
  Completed = "completed",
  Failed = "failed",
}

interface CalculationTaskResponse_CalculationArea {
  northWest: CalculationTaskResponse_CalculationAreaPoint
  northEast: CalculationTaskResponse_CalculationAreaPoint
  southEast: CalculationTaskResponse_CalculationAreaPoint
  southWest: CalculationTaskResponse_CalculationAreaPoint
}

interface CalculationTaskResponse_CalculationAreaPoint {
  longitude: number
  latitude: number
}

export interface CalculationTaskResponse_EditCalculationPointDialogOptions {
  coordinateSystem: CoordinateSystem
  zone: number | null
}

export interface CalculationTaskResponse_ReportSettings {
  makeReport: boolean
}

export interface CalculationTaskResponse_WorstCaseScenarioSettings {
  firstScenarioId: string
  firstScenarioType: ScenarioType
  secondScenarioId: string
  secondScenarioType: ScenarioType
}
