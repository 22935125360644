import './scenarioPickerScenario.scoped.scss'
import { CheckboxInput } from "components/common/formFields/CheckboxInput/CheckboxInput"

export interface ScenarioPickerScenarioProps {
  label: string
  value: string
  checked: boolean
  onChange: (checked: boolean) => void
}

export const ScenarioPickerScenario = (props: ScenarioPickerScenarioProps) => {
  return (
    <label className="scenario-list-item row align-center">
      <CheckboxInput label={props.label} value={props.checked} onChange={props.onChange} />
    </label>
  )
}

