import './textField.scoped.scss'
import { ChangeEvent, FocusEvent, ReactNode } from "react"
import { TextInput } from "components/common/formFields/textInput/textInput"

export interface TextFieldProps {
  label: string | ReactNode
  name: string
  value: string | number
  isInvalid: boolean
  errors?: string
  onChange: (event: ChangeEvent) => void
  onBlur: (event: FocusEvent) => void
}

export const TextField = (props: TextFieldProps) => {
  return (
    <label className="select-field column">
      <span className={'form-label'}>{props.label}</span>
      <TextInput
        name={props.name}
        value={props.value}
        onChange={props.onChange}
        onBlur={props.onBlur}
        isInvalid={props.isInvalid}
        errors={props.errors}
      />
    </label>
  )
}

