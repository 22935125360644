import './CalculationResolutionPicker.scoped.scss'
import { GridResolution } from "services/sicalcApi/sharedEntities/sicalcParameters"
import { MultiToggle } from "components/common/buttons/multiToggle/multiToggle"

export interface CalculationResolutionPickerProps {
  value: GridResolution
  onChange: (value: GridResolution) => void
}

export const CalculationResolutionPicker = (props: CalculationResolutionPickerProps) => {
  return (
    <MultiToggle
      value={props.value}
      options={[
        {
          label: "1",
          value: GridResolution.G_01,
        },
        {
          label: "3",
          value: GridResolution.G_03,
        },
        {
          label: "9",
          value: GridResolution.G_09,
        },
        {
          label: "27",
          value: GridResolution.G_27,
        },
        {
          label: "81",
          value: GridResolution.G_81,
        },
      ]}
      onChange={props.onChange}
    />
  )
}

