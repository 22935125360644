import './calculationTaskSetupViewer.scoped.scss'
import { CalculationTaskResponse } from "services/sicalcApi/responses/calculationTaskResponse"
import React from "react"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { FormSectionHeading } from "components/common/headings/formSectionHeading/formSectionHeading"
import { AreaSelectionMode } from "services/sicalcApi/sharedEntities/sicalcParameters"
import {
  getConstituentScenarioLabelsForWorstCaseScenario,
} from "utils/getConstituentScenarioLabelsForWorstCaseScenario"
import { BuildingResponse } from "services/sicalcApi/responses/buildingResponse"
import { AppEditionFilter } from "components/appEditionFilter/appEditionFilter"

export interface CalculationTaskSetupViewerProps {
  calculationTask: CalculationTaskResponse
  customScenarios?: CustomScenarioResponse[]
  buildings?: BuildingResponse[]
}

export const CalculationTaskSetupViewer = (props: CalculationTaskSetupViewerProps) => {

  let calculationPointsValue = ""
  if (!props.calculationTask.calculationPoints.length) {
    calculationPointsValue = "Ingen"
  } else if (props.calculationTask.calculationPoints.length === 1) {
    calculationPointsValue = "1 beregningspunkt"
  } else {
    calculationPointsValue = `${props.calculationTask.calculationPoints.length} beregningspunkter`
  }

  const getWorstCaseScenarioJsx = () => {
    const { worstCaseScenario, includedScenarios } = props.calculationTask
    if (worstCaseScenario === null || props.customScenarios === undefined) {
      return
    }
    const { firstScenario, secondScenario } = getConstituentScenarioLabelsForWorstCaseScenario(
      worstCaseScenario,
      props.calculationTask.includedScenarios,
      props.customScenarios,
    )

    return (
      <>
        <dl>
          <dt>Verste tilfelle</dt>
          <dd className="ml4">{firstScenario.name}</dd>
          <dd className="ml4">{secondScenario.name}</dd>
        </dl>
      </>
    )
  }

  const areaSelectionLabels = {
    [AreaSelectionMode.everywhere]: "Total kartlegging av flyplass",
    [AreaSelectionMode.selectedArea]: "Egendefinert område",
    [AreaSelectionMode.noArea]: "Kun punktberegning"
  };

  return (
    <div className="column gap4">
      <div className="key-value-pair column">
        <FormSectionHeading>Støykilde</FormSectionHeading>
        <span className="value">{props.calculationTask.noiseSource?.name}</span>
      </div>
      <div className="key-value-pair column">
        <FormSectionHeading>Type støykartlegging</FormSectionHeading>
        <span className="value">
          {areaSelectionLabels[props.calculationTask.sicalcParameters.areaSelectionMode]}
        </span>
      </div>
      <AppEditionFilter availableWhen={s => s.canCreateBuildings}>
        <div className="key-value-pair column">
          <FormSectionHeading>Bygninger</FormSectionHeading>
          {React.Children.toArray(props.buildings?.map((building) => (
            <span className="value">{building.name}</span>
          )))}
        </div>
      </AppEditionFilter>
      <div className="key-value-pair column">
        <FormSectionHeading>Beregningspunkter</FormSectionHeading>
        <span className="value">{calculationPointsValue}</span>
      </div>
      <div className="key-value-pair column">
        <FormSectionHeading>Scenarier</FormSectionHeading>
        {React.Children.toArray(props.calculationTask.includedScenarios.map((scenario) => (
          <span className="value">{scenario.name}</span>
        )))}
        {React.Children.toArray(props.customScenarios?.map((customScenario) => (
          <span className="value">{customScenario.name}</span>
        )))}
        {getWorstCaseScenarioJsx()}
      </div>
    </div>
  )
}

