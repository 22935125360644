import { FC } from "react"
import './authenticationGuard.scoped.scss'
import { useAuthService } from "services/auth/authService"
import { Navigate, useLocation } from "react-router-dom"


export interface AuthenticationGuardProps {

}

export const AuthenticationGuard: FC<AuthenticationGuardProps> = (props) => {
  const { userIsAuthenticated } = useAuthService()
  const location = useLocation()
  if (!userIsAuthenticated && location.pathname !== "/sign-in") {
    return (
      <Navigate to={"/sign-in"} state={{ signInRedirect: location.pathname }}></Navigate>
    )
  }
  return (
    <>
      {props.children}
    </>
  )
}
