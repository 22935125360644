import * as focusTrap from 'focus-trap'
import {
  FC,
  useEffect,
  useRef,
} from "react"

export const FocusTrap: FC = (props) => {
  const domNode = useRef<HTMLDivElement>(null)
  const trap = useRef<focusTrap.FocusTrap>()

  useEffect(() => {
    const options = {
      escapeDeactivates: false,
      allowOutsideClick: true,
    }
    trap.current = focusTrap.createFocusTrap(domNode.current!, options)
    trap.current.activate()

    return () => {
      trap.current?.deactivate()
    }
  }, [])

  return (
    <div className="focus-trap" ref={domNode}>
      {props.children}
    </div>
  )
}
