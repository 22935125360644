import { NoiseCategoryGroupListResponse } from "../responses/noiseCategoryGroupListResponse"
import { AxiosInstance } from "axios"

export class NoiseCategoryApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async getAllNoiseCategoryGroups() {
    const url = `/api/v1/noise-categories/groups`
    const response = await this.sicalcClient.get<NoiseCategoryGroupListResponse>(url)
    return response.data
  }
}
