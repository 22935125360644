import { FC } from "react"
import 'components/common/buttons/dropdownMenuButton/headerContextMenuButton.scoped.scss'

export interface HeaderContextMenuButtonProps {
  onClick: () => void
  disabled?: boolean
}

export const HeaderContextMenuButton: FC<HeaderContextMenuButtonProps> = (props) => {
  return (
    <button className="button dropdown-menu-button" onClick={props.onClick} disabled={props.disabled}>
      {props.children}
    </button>
  )
}

