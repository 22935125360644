import './vertexInputFieldsToolbar.scoped.scss'
import { ClearButton } from "components/common/buttons/clearButton/clearButton"

export interface VertexInputFieldsToolbarProps {
  onClickCopyToClipboard: () => void
  onClickPaste: () => void
  onClickResetForm: () => void
  canResetForm: boolean
  onClickDeleteAll: () => void
  canDeleteAll: boolean
}

export const VertexInputFieldsToolbar = (props: VertexInputFieldsToolbarProps) => {
  return (
    <div className="row gap4">
      <ClearButton iconName="content-copy" onClick={props.onClickCopyToClipboard}>Kopier</ClearButton>
      <ClearButton iconName="content-paste" onClick={props.onClickPaste}>Lim inn</ClearButton>
      <ClearButton
        iconName="restore"
        onClick={props.onClickResetForm}
        disabled={!props.canResetForm}
      >Tilbakestill</ClearButton>
      <ClearButton
        iconName="delete"
        onClick={props.onClickDeleteAll}
        disabled={!props.canDeleteAll}
      >Slett alle</ClearButton>
    </div>
  )
}

