import {
  Navigate,
  Route,
  Routes,
} from "react-router-dom"
import { SignInPage } from "pages/signInPage"
import { NotFoundPage } from "pages/notFoundPage"
import { ProjectsListPage } from "pages/projectsListPage"
import { ProjectPage } from "pages/projectPage"
import { CalculationTaskPage } from "pages/calculationTaskPage"

export interface RouterProps {

}

export const AppRouter = (props: RouterProps) => {
  return (
    <Routes>
      <Route index element={<Navigate to={"projects"} />}/>
      <Route path="projects" element={<ProjectsListPage />} />
      <Route path="projects/:projectId" element={<ProjectPage />} />
      <Route path="calculation-tasks/:calculationTaskId" element={<CalculationTaskPage />} />
      <Route path="/sign-in" element={<SignInPage />} />
      <Route path="/*" element={<NotFoundPage />} />
    </Routes>
  )
}


