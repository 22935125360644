import './signInForm.scoped.scss'
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import { useFormik } from "formik"
import * as yup from "yup"

export interface SignInFormProps {
  onSubmit: (credentials: {username: string, password: string}) => void
  authenticationErrorMessage?: string
}

interface FormDataModel {
  username: string
  password: string
}

const validationSchema = yup.object().shape({
  username: yup.string()
    .email("Må være en e-postadresse")
    .required("Påkrevd"),
  password: yup.string()
    .required("Påkrevd"),
})

export const SignInForm = (props: SignInFormProps) => {
  const initialValues: FormDataModel = {
    username: '',
    password: '',
  }

  const handleSubmission = async (values: FormDataModel) => {
    props.onSubmit(values)
  }

  const form = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: handleSubmission,
    validateOnBlur: false,
    validateOnChange: false,
    validateOnMount: false,
  })
  return (
    <form onSubmit={form.handleSubmit} className="sign-in-form column gap4">
      <div className="form-field column gap1">
        <label className="column">
          <span className="form-label">Brukernavn</span>
          <input
            className={`input ${form.errors.username ? 'invalid' : ''}`}
            type="text"
            name={"username"}
            autoComplete="email"
            autoFocus
            value={form.values.username}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </label>
        {form.errors.username && (
          <div className="validation-error">
            {form.errors.username}
          </div>
        )}
      </div>

      <div className="form-field column gap1">
        <label className="column">
          <span className="form-label">Passord</span>
          <input
            className="input"
            type="password"
            autoComplete="current-password"
            name={"password"}
            value={form.values.password}
            onChange={form.handleChange}
            onBlur={form.handleBlur}
          />
        </label>
        {form.errors.password && (
          <div className="validation-error">
            {form.errors.password}
          </div>
        )}
      </div>

      {props.authenticationErrorMessage && (
        <div className="validation-error">
          {props.authenticationErrorMessage}
        </div>
      )}
      <div className="controls column">
        <PrimaryButton submit onClick={form.submitForm}>Logg inn</PrimaryButton>
      </div>
    </form>
  )
}
