import React from "react"
import './projectPageCalculationTaskActions.scoped.scss'
import { ClearButton } from "../../common/buttons/clearButton/clearButton"
import { CalculationTaskStatus } from "../../../services/sicalcApi/responses/calculationTaskResponse"

export interface ProjectPageCalculationTaskActionsProps {
  onClickDelete: () => void
  onClickDownloadResults: () => void
  onClickCancelCalculation: () => void
  calculationTaskStatus: CalculationTaskStatus
}

export const ProjectPageCalculationTaskActions = (props: ProjectPageCalculationTaskActionsProps) => {
  const actions = []

  if (props.calculationTaskStatus !== CalculationTaskStatus.Running) {
    actions.push(
      <ClearButton iconName="delete" onClick={props.onClickDelete}>
        Slett
      </ClearButton>,
    )
  }

  if (props.calculationTaskStatus === CalculationTaskStatus.Completed) {
    actions.push(
      <ClearButton iconName="download" onClick={props.onClickDownloadResults}>
        Last ned resultater
      </ClearButton>,
    )
  }

  if (props.calculationTaskStatus === CalculationTaskStatus.Running) {
    actions.push(
      <ClearButton iconName="close-thick" onClick={props.onClickCancelCalculation}>
        Avbryt
      </ClearButton>,
    )
  }

  return (
    <div className="row gap3">
      {React.Children.toArray(actions)}
    </div>
  )
}

