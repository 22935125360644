import { useNavigate, useParams } from "react-router-dom"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { useCallback, useEffect } from "react"
import { ProjectResponse } from "services/sicalcApi/responses/projectResponse"
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { PageHeader } from "components/common/pageHeader/pageHeader"
import { ProjectPageTemplate } from "components/project/projectPageTemplate/projectPageTemplate"
import { CalculationTaskListItem } from "components/project/calculationTaskListItem/calculationTaskListItem"
import { CalculationTaskList } from "components/project/calculationTaskList/calculationTaskList"
import { PageHeading } from "components/common/headings/pageHeading/pageHeading"
import { ProjectPageBreadcrumbs } from "components/project/projectPageBreadcrumbs"
import { useSignalrService } from "services/signalrService/signalrService"
import { useStateRef } from "hooks/useStateRef"
import { HeaderContextMenu } from "components/headerContextMenu/headerContextMenu"
import { HeaderContextMenuButton } from "components/common/buttons/dropdownMenuButton/headerContextMenuButton"
import { NavbarPageHeading } from "components/calculationTask/navbarPageHeading/navbarPageHeading"
import { useModalService } from "services/modal/modalService"
import { ConfirmationModal } from "components/common/modals/confirmationModal/confirmationModal"
import { toast } from "react-hot-toast"
import {
  CreateCalculationTaskModal,
  CreateCalculationTaskModalResult,
} from "components/calculationTask/createCalculationTaskModal/createCalculationTaskModal"
import { AppEditionFilter } from "components/appEditionFilter/appEditionFilter"

export function ProjectPage() {
  let { projectId } = useParams()
  const navigate = useNavigate()
  const { showModal } = useModalService()
  const { projectApi, calculationTaskApi } = useSicalcApiService()
  const [ project, setProject, projectRef ] = useStateRef<ProjectResponse>()

  const refreshProject = useCallback(async () => {
    const response =  await projectApi.get(projectId!)
    setProject(response)
  }, [projectApi, projectId, setProject])


  useEffect(() => {
    refreshProject()
  }, [ refreshProject ])

  const deleteCalculationTask = async (calculationTaskId: string) => {
    await calculationTaskApi.delete(calculationTaskId)
    await refreshProject()
  }
  const cancelCalculation = async (calculationTaskId: string) => {
    await calculationTaskApi.cancel(calculationTaskId)
    await refreshProject()
  }
  const triggerResultsDownload = async (calculationTaskId: string) => {
    await calculationTaskApi.triggerResultsFileDownload(calculationTaskId)
    await refreshProject()
  }

  const createCalculationTask = async () => {
    if (project === undefined || projectId === undefined) {
      return
    }

    const result = await showModal(context =>
      <CreateCalculationTaskModal
        modalContext={context}
        projectId={projectId!}
        defaultNoiseSourceId={project.defaultNoiseSource?.id}
      />) as CreateCalculationTaskModalResult

    if (result !== undefined) {
      navigate("/calculation-tasks/" + result.id)
    }
  }

  const signalrService = useSignalrService()
  signalrService.onNewCalculationResult(message => {
    if (projectRef.current?.calculationTasks.some(c => c.id === message.calculationTaskId)) {
      refreshProject()
    }
  })
  signalrService.onCalculationTaskFailed(message => {
    if (projectRef.current?.calculationTasks.some(c => c.id === message.calculationTaskId)) {
      refreshProject()
    }
  })
  signalrService.onCalculationProgressUpdate(message => {
    if (projectRef.current?.calculationTasks.some(c => c.id === message.calculationTaskId)) {
      refreshProject()
    }
  })

  const deleteProject = async () => {
    const confirmed = await showModal(context =>
      <ConfirmationModal
        modalContext={context}
        heading="Vil du slette prosjektet?"
        message="Prosjektets beregninger blir også slettet."
        confirmButtonText="Slett"
        confirmButtonStyle="danger"
      />) as boolean

    if (confirmed) {
      try {
        await projectApi.delete(project!.id)
        navigate("/projects")
      } catch {
        toast.error("Feil ved sletting av prosjektet")
      }
    }
  }

  return (
    <BasePageLayout
      header={
        <PageHeader>
          <div className="row align-center">
            <NavbarPageHeading>{project?.name}</NavbarPageHeading>
            <HeaderContextMenu>
              <HeaderContextMenuButton disabled onClick={() => {}} >Endre prosjektnavn</HeaderContextMenuButton>
              <HeaderContextMenuButton disabled onClick={() => {}}>Se prosjektinformasjon</HeaderContextMenuButton>
              <AppEditionFilter availableWhen={s => s.canCreateProjects}>
                <HeaderContextMenuButton onClick={deleteProject} >Slett prosjektet</HeaderContextMenuButton>
              </AppEditionFilter>
            </HeaderContextMenu>
          </div>
        </PageHeader>
      }
    >
      <ProjectPageTemplate
        onClickCreateCalculationTask={createCalculationTask}
        heading={(
          <PageHeading>
            {project?.name ?? <span>&nbsp;</span>}
          </PageHeading>
        )}
        breadcrumbs={(
          <ProjectPageBreadcrumbs project={project} />
        )}
      >
        {!!project?.calculationTasks?.length && (
          <CalculationTaskList>
            { project?.calculationTasks.map((calculationTask, index) => (
              <CalculationTaskListItem
                key={index}
                calculationTask={calculationTask}
                actionProps={{
                  onClickDelete: () => deleteCalculationTask(calculationTask.id),
                  onClickDownloadResults: () => triggerResultsDownload(calculationTask.id),
                  onClickCancelCalculation: () => cancelCalculation(calculationTask.id),
                  calculationTaskStatus: calculationTask.status,
                }}
              />
            ))}
          </CalculationTaskList>
        )}
      </ProjectPageTemplate>
    </BasePageLayout>
  )
}
