import './pageHeader.scoped.scss'
import { FC } from "react"
import { Link } from "react-router-dom"
import { useAuthService } from "services/auth/authService"
import { PageHeaderButton } from "components/common/pageHeaderButton/pageHeaderButton"

export interface PageHeaderProps {
}

export const PageHeader: FC<PageHeaderProps> = (props) => {
  const { signOut } = useAuthService()

  return (
    <header className="page-header row align-center space-between">
      <div className="left">
        <Link to="/" >
          <img className="sintef-logo" src="/sinteflogo.png" alt="SINTEF"/>
        </Link>
      </div>
      <div className="center">
        {props.children}
      </div>
      <div className="right">
        <PageHeaderButton onClick={signOut} iconName="logout-variant">Logg ut</PageHeaderButton>
      </div>
    </header>
  )
}

