import { FC } from "react"
import './stackedList.scoped.scss'

export interface StackedListProps {

}

export const StackedList: FC<StackedListProps> = (props) => {
  return (
    <ul className="stacked-list">
      {props.children}
    </ul>
  )
}
