import { FC } from "react"
import 'components/calculationTask/navbarPageHeading/navbarPageHeading.scss'

export interface NavbarPageHeadingProps {

}

export const NavbarPageHeading: FC<NavbarPageHeadingProps> = (props) => {
  return (
    <h1 className="calculation-task-heading">{props.children}</h1>
  )
}

