import { useEffect } from "react"

export const useEscapeKeyHandler = (callback: () => void) =>  {
  useEffect(() => {
    const handler = (event: KeyboardEvent) => {
      if (event.key === "Escape" || event.key === "Esc") {
        callback()
      }
    }
    window.addEventListener('keydown', handler)
    return () => {
      window.removeEventListener('keydown', handler)
    }
  }, [callback])
}
