import { MutableRefObject, useEffect } from "react"

export const useEffectOnClickOutside = (
  targetElementRef: MutableRefObject<HTMLElement | null>,
  callback: () => void,
) => {
  useEffect(() => {
    const clickEventHandler = (event: any) => {
      if (targetElementRef.current && !targetElementRef.current.contains(event.target)) {
        callback()
      }
    }

    document.addEventListener('click', clickEventHandler, true)

    return () => {
      document.removeEventListener('click', clickEventHandler, true)
    }
  }, [callback, targetElementRef])
}
