import { CalculationTaskResponse } from "services/sicalcApi/responses/calculationTaskResponse"
import React from "react"
import { BreadCrumbs } from "components/common/breadCrumbs/breadCrumbs"

export interface CalculationTaskBreadCrumbsProps {
  calculationTask: CalculationTaskResponse
  nameOverride?: string
}
export const CalculationTaskBreadCrumbs = (props: CalculationTaskBreadCrumbsProps) => {
  const { project, name } = props.calculationTask
  const pathSegments = [
    {
      label: "Prosjekter",
      path: "..",
    },
    {
      label: project.name,
      path: `/projects/${project.id}`,
    },
    {
      label: props.nameOverride ?? name ?? "(uten navn)",
      path: null,
    },
  ]
  return (
    <BreadCrumbs segments={pathSegments} />
  )
}
