import './calculationPointSidebarList.scoped.scss'
import React from "react"
import { CalculationPointListItem } from "components/calculationTask/calculationPointListItem/calculationPointListItem"
import { CalculationPoint } from "components/calculationTask/calculationTaskSetupView/formDataModel"
import { StackedList } from "components/common/stackedList/stackedList"
import { StackedListItem } from "components/common/stackedListItem/stackedListItem"
import { point } from "leaflet"

export interface CalculationPointSidebarListProps {
  calculationPoints: CalculationPoint[]
  onClickDelete: (point: CalculationPoint) => void
  onClickShowInMap: (point: CalculationPoint) => void
}

export const CalculationPointSidebarList = (props: CalculationPointSidebarListProps) => {
  return (
    <div className="calculation-point-list column">
      <StackedList>
        {React.Children.toArray(props.calculationPoints.map(c => (
          <StackedListItem>
            <CalculationPointListItem
              point={c}
              onClickDelete={() => props.onClickDelete(c)}
              onClickShowInMap={() => props.onClickShowInMap(c)}
            />
          </StackedListItem>
        )))}
      </StackedList>
    </div>
  )
}
