import './calculationTaskFailedView.scoped.scss'
import { PageHeader } from "components/common/pageHeader/pageHeader"
import { NavbarPageHeading } from "components/calculationTask/navbarPageHeading/navbarPageHeading"
import {
  CalculationTaskPageTemplate,
} from "components/calculationTask/calculationTaskPageTemplate/calculationTaskPageTemplate"
import {
  CalculationTaskPageSidebar,
} from "components/calculationTask/calculationTaskPageSidebar/calculationTaskPageSidebar"
import { CalculationTaskBreadCrumbs } from "components/calculationTask/calculationTaskBreadCrumbs"
import { LatLongPoint, MapPane } from "components/calculationTask/mapPane/mapPane"
import { BasePageLayout } from "components/layout/basePageLayout/basePageLayout"
import { CalculationTaskResponse } from "services/sicalcApi/responses/calculationTaskResponse"

export interface CalculationTaskFailedViewProps {
  calculationTask: CalculationTaskResponse
}

export const CalculationTaskFailedView = (props: CalculationTaskFailedViewProps) => {
  const noiseSourceLocation: LatLongPoint = {
    latitude: props.calculationTask.noiseSource!.latitude,
    longitude: props.calculationTask.noiseSource!.longitude,
  }

  return (
    <BasePageLayout
      header={
        <PageHeader>
          <NavbarPageHeading>{props.calculationTask.name}</NavbarPageHeading>
        </PageHeader>
      }
    >
      <CalculationTaskPageTemplate
        sidebar={
          <CalculationTaskPageSidebar
            breadcrumbs={(<CalculationTaskBreadCrumbs calculationTask={props.calculationTask} />)}
          >
            <div className="sidebar-content column align-center gap4">
              <div className="column align-center gap2">
                <i className="error-icon mdi mdi-alert-outline"></i>
                <h2 className="sidebar-heading">Beregningen feilet</h2>
              </div>
            </div>
          </CalculationTaskPageSidebar>
        }
      >
        <MapPane
          mapLocation={noiseSourceLocation}
        />
      </CalculationTaskPageTemplate>
    </BasePageLayout>
  )
}

