import './calculationTaskTitleEditor.scoped.scss'
import { FormEvent, useState } from "react"
import { NavbarPageHeading } from "components/calculationTask/navbarPageHeading/navbarPageHeading"

export interface CalculationTaskTitleEditorProps {
  editing: boolean
  initialName: string | undefined
  onNameChanged: (newName: string | undefined) => void
  onCancelEdit: () => void
}

export const CalculationTaskTitleEditor = (props: CalculationTaskTitleEditorProps) => {
  const [ name, setName ] = useState<string | undefined>(props.initialName)

  const onClickCancel = () => {
    setName(props.initialName)
    props.onCancelEdit()
  }

  const onSubmit = (event: FormEvent) => {
    event.preventDefault()
    const newName = name?.trim()
    setName(newName)
    props.onNameChanged(newName)
  }

  if (!props.editing) {
    return (
      <NavbarPageHeading>{props.initialName}</NavbarPageHeading>
    )
  }

  return (
    <form className="calculation-task-name-form row align-center" onSubmit={onSubmit}>
      <label className="row align-center gap1">
        <input
          className="calculation-task-name-input"
          type="text"
          name="name"
          value={name}
          autoFocus
          onFocus={e => e.target.select()}
          onChange={(e) => setName(e.target.value)}
        />
        <button
          className="button calculation-task-name-input-button submit"
          type="submit"
        >
          <span className="row align-center">
            <i className="mdi mdi-18px mdi-check mr1"></i>
            <span>Lagre</span>
          </span>
        </button>
        <button
          className="button calculation-task-name-input-button cancel"
          type="button"
          onClick={onClickCancel}
        >
          <span className="row align-center">
            <i className="mdi mdi-18px mdi-close mr1"></i>
            <span>Avbryt</span>
          </span>
        </button>
      </label>
    </form>
  )
}

