import './createCalculationTaskModal.scoped.scss'
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { ModalProps } from "services/modal/modalService"
import { toast } from "react-hot-toast"
import { useFormik } from "formik"
import * as yup from "yup"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import { TextField } from "components/common/formFields/textField/textField"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import { NoiseSourceInput } from "components/common/formFields/noiseSourceInput/noiseSourceInput"
import { CalculationResultResponse } from "services/sicalcApi/responses/calculationResultResponse"
import { AppEditionFilter } from "components/appEditionFilter/appEditionFilter"

export interface CreateCalculationTaskModalProps extends ModalProps {
  projectId: string
  defaultNoiseSourceId: string | undefined
}

interface FormDataModel {
  name: string
  description: string
  noiseSourceId: string
}

const formValidationSchema = yup.object().shape({
  name: yup.string()
    .required("Påkrevd")
    .max(70, "Navnet er for langt (maks 70 tegn)"),
  description: yup.string()
    .max(250, "Maks 250 tegn"),
})

export type CreateCalculationTaskModalResult = CalculationResultResponse | undefined

export const CreateCalculationTaskModal = (props: CreateCalculationTaskModalProps) => {
  const { calculationTaskApi } = useSicalcApiService()
  const handleSubmission = async (values: FormDataModel) => {
    const { name, description, noiseSourceId } = values
    try {
      const result = await calculationTaskApi.create({
        projectId: props.projectId,
        name: name.trim(),
        description: description?.trim(),
        noiseSourceId,
      })
      props.modalContext.close(result)
    } catch {
      toast.error("Kunne ikke opprette beregningen")
    }
  }

  const initialValues: FormDataModel = {
    name: '',
    description: '',
    noiseSourceId: props.defaultNoiseSourceId ?? '',
  }

  const form = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: handleSubmission,
  })

  const dismiss = () => {
    props.modalContext.dismiss()
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      heading="Ny beregning"
      actions={
        <>
          <LightButton onClick={dismiss}>Avbryt</LightButton>
          <PrimaryButton onClick={form.handleSubmit}>
            Opprett
          </PrimaryButton>
        </>
      }
    >
      <form className="column gap4">
        <TextField
          label="Navn*"
          name="name"
          value={form.values.name}
          isInvalid={form.touched.name === true && form.errors.name !== undefined}
          errors={form.errors.name}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <TextField
          label="Kommentar"
          name="description"
          value={form.values.description}
          isInvalid={form.touched.description === true && form.errors.description !== undefined}
          errors={form.errors.description}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
        />
        <AppEditionFilter availableWhen={s => s.canSelectNoiseSourceWhenCreatingCalculationTasks}>
          <label className="column gap1">
            <span className="form-label">Støykilde</span>
            <NoiseSourceInput
              value={form.values.noiseSourceId}
              onChange={value => form.setFieldValue("noiseSourceId", value)}
              onBlur={form.handleBlur}
            />
          </label>
        </AppEditionFilter>
      </form>
    </ModalBase>
  )
}

