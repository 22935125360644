import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios"
import { AuthService } from "services/auth/authService"
import { ForbiddenResponseError } from "services/sicalcApi/errors/ForbiddenResponseError"

export class SicalcClientFactory {
  private authService: AuthService

  constructor(authService: AuthService) {
    this.authService = authService
  }

  public create() {
    const sicalcClient = axios.create({ baseURL: '' })

    sicalcClient.interceptors.request.use(this.requestPipeline, this.onRequestError)
    sicalcClient.interceptors.response.use(this.successfulResponsePipeline, this.handleErrors)

    return sicalcClient
  }

  requestPipeline = async (request: AxiosRequestConfig) => {
    await this.addTokenToOutgoingRequests(request)
    return request
  }

  onRequestError = async (error: any) => {
    return error
  }

  successfulResponsePipeline = async (response: AxiosResponse) => {
    return response
  }

  handleErrors = async (error: AxiosError) => {
    await this.signOutWhenUnauthorized(error)
    if (error.response?.status === 403) {
      throw new ForbiddenResponseError()
    }

    throw error
  }

  addTokenToOutgoingRequests = async (request: AxiosRequestConfig) => {
    if (this.authService.currentUser?.token) {
      request.headers!.Authorization = `Bearer ${this.authService.currentUser?.token}`
    }
    return request
  }

  signOutWhenUnauthorized = async (error: AxiosError) => {
    if (error.response?.status === 401) {
      console.info("Signed the user out due to a 401 response")
      this.authService.signOut()
    }
  }
}
