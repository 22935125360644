import './scenarioPicker.scoped.scss'
import { CreateCustomScenarioModal } from "components/createCustomScenarioModal/createCustomScenarioModal"
import { NoiseSourceResponse_Scenario } from "services/sicalcApi/responses/noiseSourceResponse"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { ScenarioPickerScenario } from "components/calculationTask/scenarioPickerScenario/scenarioPickerScenario"
import {
  ScenarioPickerCustomScenario,
} from "components/calculationTask/scenarioPickerCustomScenario/scenarioPickerCustomScenario"
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import { FormSectionHeading } from "components/common/headings/formSectionHeading/formSectionHeading"
import { useModalService } from "services/modal/modalService"
import { AppEdition } from "services/appEdition/models/appEdition"
import { AppEditionFilter } from "components/appEditionFilter/appEditionFilter"
import {
  CreateWorstCaseScenarioModal,
  CreateWorstCaseScenarioModalResult,
} from "components/createWorstCaseScenarioModal/createWorstCaseScenarioModal"
import { CalculationTaskResponse_WorstCaseScenarioSettings } from "services/sicalcApi/responses/calculationTaskResponse"
import {
  ScenarioPickerWorstCaseScenario,
} from "components/calculationTask/ScenarioPickerWorstCaseScenario/ScenarioPickerWorstCaseScenario"
import {
  UpdateCalculationTaskRequest_WorstCaseScenarioSettings,
} from "services/sicalcApi/requests/updateCalculationTaskRequest"
import { ConfirmationModal } from "components/common/modals/confirmationModal/confirmationModal"
import { StackedList } from "components/common/stackedList/stackedList"
import { StackedListItem } from "components/common/stackedListItem/stackedListItem"

export interface ScenarioPickerProps {
  calculationTaskId: string
  availableScenarios?: NoiseSourceResponse_Scenario[]
  customScenarios?: CustomScenarioResponse[]
  includedScenarioIds: string[]
  onScenarioSelectionStateChanged: (scenarioId: string, selected: boolean) => void
  onClickDeleteCustomScenario: (customScenarioId: string) => void
  onCustomScenarioCreated: () => void
  worstCaseScenario: CalculationTaskResponse_WorstCaseScenarioSettings | null
  onWorstCaseScenarioUpdated: (newValue: UpdateCalculationTaskRequest_WorstCaseScenarioSettings | null) => void
}

export const ScenarioPicker = (props: ScenarioPickerProps) => {
  const { showModal } = useModalService()

  const createCustomScenario = async () => {
    const result = await showModal(context =>
      <CreateCustomScenarioModal
        modalContext={context}
        calculationTaskId={props.calculationTaskId}
        scenarios={props.availableScenarios}
        onCreated={props.onCustomScenarioCreated}
      />)
  }
  const showWorstCaseScenarioEditorModal = async () => {
    const result = await showModal(context =>
      <CreateWorstCaseScenarioModal
        modalContext={context}
        availableScenarios={props.availableScenarios}
        customScenarios={props.customScenarios}
        initialValues={props.worstCaseScenario ?? undefined}
      />) as CreateWorstCaseScenarioModalResult | undefined

    if (result !== undefined) {
      props.onWorstCaseScenarioUpdated(result)
    }
  }

  const deleteWorstCaseScenario = () => {
    props.onWorstCaseScenarioUpdated(null)
  }

  const onScenarioSelectionStateChanged = async (scenarioId: string, isSelected: boolean) => {
    // If the scenario has been deselected, check if it's part of the worst case scenario

    const hasWorstCaseScenario = props.worstCaseScenario !== null
    if (isSelected || !hasWorstCaseScenario) {
      props.onScenarioSelectionStateChanged(scenarioId, isSelected)
      return
    }

    const isInWorstCase = props.worstCaseScenario!.firstScenarioId === scenarioId
      || props.worstCaseScenario!.secondScenarioId === scenarioId

    if (!isInWorstCase) {
      props.onScenarioSelectionStateChanged(scenarioId, isSelected)
      return
    }

    const confirmed = await confirmDeletionOfWorstCaseScenario()

    if (!confirmed) {
      return
    } else {
      deleteWorstCaseScenario()
      props.onScenarioSelectionStateChanged(scenarioId, isSelected)
    }
  }

  const confirmDeletionOfWorstCaseScenario = async () => {
    // A scenario that is included in the worst case scenario has been attempted deselected.
    // Prompt to delete the worst case scenario, otherwise ignore the action
    const confirmed = await showModal(context =>
      <ConfirmationModal
        modalContext={context}
        heading="Scenariet brukes i verste tilfelle-scenariet"
        confirmButtonText="Slett verste tilfelle-scenariet"
        confirmButtonStyle="danger"
      >
        <p>Du har valgt å fjerne et av scenariene som danner grunnlaget for verste tilfelle-scenariet.</p>
        <p>Hvis du fortsetter, blir verste tilfelle-scenariet slettet.</p>
      </ConfirmationModal>) as boolean

    return confirmed
  }

  const onClickDeleteCustomScenario = async (customScenarioId: string) => {
    const hasWorstCaseScenario = props.worstCaseScenario !== null
    const isInWorstCase = props.worstCaseScenario?.firstScenarioId === customScenarioId
      || props.worstCaseScenario?.secondScenarioId === customScenarioId

    if (!hasWorstCaseScenario || !isInWorstCase) {
      props.onClickDeleteCustomScenario(customScenarioId)
      return
    }

    // A custom scenario that is included in the worst case scenario has been attempted deleted.
    // Prompt to delete the worst case scenario, otherwise ignore the action
    const confirmed = await confirmDeletionOfWorstCaseScenario()

    if (confirmed) {
      deleteWorstCaseScenario()
      props.onClickDeleteCustomScenario(customScenarioId)
    }
  }

  return (
    <div className="scenario-picker column gap4">
      <FormSectionHeading>Scenarier</FormSectionHeading>
      <div className="scrollable">
        <StackedList>
          {props.availableScenarios?.map((scenario, index) => (
            <StackedListItem key={scenario.id}>
              <ScenarioPickerScenario
                label={`${scenario.targetYear} – ${scenario.name}`}
                checked={props.includedScenarioIds.includes(scenario.id)}
                onChange={selected => onScenarioSelectionStateChanged(scenario.id, selected)}
                value={scenario.id}
              />
            </StackedListItem>
          ))}

          {props.customScenarios?.map((customScenario, index) => (
            <StackedListItem key={customScenario.id}>
              <ScenarioPickerCustomScenario
                name={`${customScenario.targetYear} – ${customScenario.name}`}
                onClickDelete={() => onClickDeleteCustomScenario(customScenario.id)}
              />
            </StackedListItem>
          ))}
          {props.worstCaseScenario && (
            <StackedListItem key={"worst-case"}>
              <ScenarioPickerWorstCaseScenario
                availableScenarios={props.availableScenarios}
                customScenarios={props.customScenarios}
                worstCaseScenario={props.worstCaseScenario}
                onClickDelete={deleteWorstCaseScenario}
                onClickEdit={showWorstCaseScenarioEditorModal}
              />
            </StackedListItem>
          )}
        </StackedList>
      </div>
      <div className="column gap3 align-start">
        <AppEditionFilter availableWhen={s => s.canCreateCustomScenarios}>
          <ClearButton iconName={"plus"} onClick={createCustomScenario}>Legg til egendefinert scenario</ClearButton>
        </AppEditionFilter>
        {props.worstCaseScenario === null && (
          <ClearButton
            iconName={"plus"}
            onClick={showWorstCaseScenarioEditorModal}
          >Legg til verste tilfelle-scenario</ClearButton>
        )}
      </div>
    </div>
  )
}
