import { CalculationTaskResponse } from "../responses/calculationTaskResponse"
import { CreateCalculationTaskRequest } from "../requests/createCalculationTaskRequest"
import { UpdateCalculationTaskRequest } from "services/sicalcApi/requests/updateCalculationTaskRequest"
import { CalculationResultResponse } from "services/sicalcApi/responses/calculationResultResponse"
import { AxiosInstance } from "axios"
import { CalculationTaskProgressResponse } from "services/sicalcApi/responses/calculationTaskProgressResponse"

export class CalculationTaskApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async create(request: CreateCalculationTaskRequest) {
    const url =`/api/v1/calculation-tasks`
    const response = await this.sicalcClient.post<CalculationTaskResponse>(url, request)
    return response.data
  }

  public async createCopy(calculationTaskId: string) {
    const url =`/api/v1/calculation-tasks/${calculationTaskId}/copy`
    const response = await this.sicalcClient.post<CalculationTaskResponse>(url)
    return response.data
  }

  public async get(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}`
    const response = await this.sicalcClient.get<CalculationTaskResponse>(url)
    return response.data
  }

  public async getResults(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/results`
    const response = await this.sicalcClient.get<CalculationResultResponse>(url)
    return response.data
  }

  public async delete(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}`
    await this.sicalcClient.delete(url)
  }

  public async update(calculationTaskId: string, request: UpdateCalculationTaskRequest) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}`
    const response = await this.sicalcClient.put<CalculationTaskResponse>(url, request)
    return response.data
  }

  public async start(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/task-execution`
    await this.sicalcClient.post(url)
  }

  public async cancel(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/task-execution`
    await this.sicalcClient.delete(url)
  }

  public async resetToDraft(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/reset-to-draft`
    await this.sicalcClient.post(url)
  }

  public async reset(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/reset`
    await this.sicalcClient.post(url)
  }

  public async triggerResultsFileDownload(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/results-file`
    const response = await this.sicalcClient.get(url, { responseType: "blob" })
    const blob = new Blob([response.data])
    const contentDispositionHeader = response.headers["content-disposition"]
    const fileName = this.getFileNameFromContentDispositionHeader(contentDispositionHeader)
      ?? "Resultater.zip"
    this.showDownloadPrompt(blob, fileName)
  }

  public async getProgress(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/calculation-progress`
    const response = await this.sicalcClient.get<CalculationTaskProgressResponse>(url)
    return response.data
  }

  private getFileNameFromContentDispositionHeader(headerText: string) {
    const fileNameMatch = headerText.match(/(?<=filename\*=UTF-8'').*(?=$)/gm)
    if (fileNameMatch !== null) {
      return decodeURIComponent(fileNameMatch[0])
    }
    return null
  }

  private showDownloadPrompt(blob: Blob, fileName: string) {
    const fileURL = window.URL.createObjectURL(blob)
    const fileLink = document.createElement('a')

    fileLink.href = fileURL
    fileLink.setAttribute('download', fileName)
    document.body.appendChild(fileLink)

    fileLink.click()

    document.body.removeChild(fileLink)
  }
}
