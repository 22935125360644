import './confirmCalculationTaskModal.scoped.scss'
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { ModalProps } from "services/modal/modalService"

import React, {
  ChangeEvent,
  useRef,
  useState,
} from "react"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"

export interface ConfirmCalculationTaskModalProps extends ModalProps {
  warningType: number
  calculationPointCount: number
  buildingCount: number
  calculationArea: number
  gridResolution: number
  numGridPoints: number
}

export interface ConfirmCalculationTaskModalResult {
  accepted: boolean
}

export const ConfirmCalculationTaskModal = (props: ConfirmCalculationTaskModalProps) => {

  const submit = () => {
    props.modalContext.close({ accepted: true } as ConfirmCalculationTaskModalResult)
  }

  const dismiss = () => {
    props.modalContext.close({ accepted: false } as ConfirmCalculationTaskModalResult)
  }

  const buildAndPoint = () => {
    let S = ""
    if (props.buildingCount > 0) {
      S += props.buildingCount + " bygning"
      if (props.buildingCount > 1)
        S += "er"
    }
    if (props.buildingCount > 0 && props.calculationPointCount > 0)
      S += " og "
    if (props.calculationPointCount > 0) {
      S += props.calculationPointCount + " punkt"
    }
    return S
  }

  const area = () => {
    let A = props.calculationArea // m2

    if (A > 100000) { // area > 0.1 km2
      A = Math.round((A/1000000) * 100) / 100 // Round to two decimals
      return (<>{A} km<sup>2</sup></>);

    } else { // area < 0.1 km2
      A = Math.round(A) // Round to whole number
      return (<>{A} km<sup>2</sup></>);
    }
  }

  const warningString = (warningType: Number) => {
    switch (warningType) {
      case 1:
        return (
          <div>
            <p>Du har valgt 'Total kartlegging av flyplass' i kombinasjon med beregning av { buildAndPoint() }.</p>
            <p>Vurder om 'Egendefinert område' eller 'Kun punktberegning' er mer passende.</p>
          </div>
        )
      case 2:
        return (
          <div>
            <p>Du har valgt 'Egendefinert område' som dekker et areal omtrent { area() }, i kombinasjon med oppløsning { props.gridResolution } m.</p>
            <p>Dette tilsvarer omtrent { props.numGridPoints } beregningspunkt, og kan medføre lang beregningstid.</p>
          </div>
        )
      default:
        return "Her skulle det kommet en relevant advarsel"
    }
  }
  
  return (
    <ModalBase
      onDismiss={dismiss}
      heading="Beregning"
      actions={
        <>
          <LightButton
            onClick={dismiss}
          >
          Avbryt
          </LightButton>
          <PrimaryButton
            onClick={submit}
          >
          Start
          </PrimaryButton>
        </>
      }
    >
      <div className="confirm-calculation-task-modal column gap4">
        <div className="row align-end">
          { warningString(props.warningType) }
        </div>
      </div>
    </ModalBase>
  )
}

