import { useParams } from "react-router-dom"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"
import {
  useCallback,
  useEffect,
  useState,
} from "react"
import { CalculationTaskResponse, CalculationTaskStatus } from "services/sicalcApi/responses/calculationTaskResponse"
import { CalculationTaskSetupView } from "components/calculationTask/calculationTaskSetupView/calculationTaskSetupView"
import {
  CalculationTaskResultsView,
} from "components/calculationTask/calculationTaskResultsView/calculationTaskResultsView"
import {
  CalculationTaskInProgressView,
} from "components/calculationTask/calculationTaskInProgressView/calculationTaskInProgressView"
import { useSignalrService } from "services/signalrService/signalrService"
import {
  CalculationTaskFailedView,
} from "components/calculationTask/calculationTaskFailedView/calculationTaskFailedView"
import {
  CalculationTaskLoadingView,
} from "components/calculationTask/calculationTaskLoadingView/calculationTaskLoadingView"

export interface CalculationTaskPageProps {
}

export function CalculationTaskPage() {

  let { calculationTaskId } = useParams()
  const { calculationTaskApi } = useSicalcApiService()
  const [ calculationTask, setCalculationTask ] = useState<CalculationTaskResponse>()

  const refreshCalculationTask = useCallback(async () => {
    const response =  await calculationTaskApi.get(calculationTaskId!)
    setCalculationTask(response)
  }, [ calculationTaskApi, calculationTaskId ])

  useEffect(() => {
    refreshCalculationTask()
  }, [ refreshCalculationTask ])

  const signalrService = useSignalrService()

  const [componentKey, setComponentKey] = useState(1)
  const forceReload = async () => {
    await refreshCalculationTask()
    setComponentKey(k => k+1)
  }

  signalrService.onNewCalculationResult(message => {
    if (message.calculationTaskId === calculationTaskId) {
      refreshCalculationTask()
    }
  })
  signalrService.onCalculationTaskFailed(message => {
    if (message.calculationTaskId === calculationTaskId) {
      refreshCalculationTask()
    }
  })
  signalrService.onCalculationProgressUpdate(message => {
    if (message.calculationTaskId === calculationTaskId) {
      setCalculationTask(oldValue => oldValue !== undefined
        ? {
          ...oldValue,
          progressPercent: message.progressPercent ,
          progressStatusMessage: message.statusMessage,
        }
        : undefined)
    }
  })

  switch (calculationTask?.status) {
    case CalculationTaskStatus.Draft:
      return (
        <CalculationTaskSetupView
          calculationTask={calculationTask}
          onCalculationStarted={refreshCalculationTask}
          onReset={forceReload}
          key={componentKey}
        />
      )
    case CalculationTaskStatus.Running:
      return (
        <CalculationTaskInProgressView
          calculationTask={calculationTask}
          onCalculationCanceled={refreshCalculationTask}
        />
      )
    case CalculationTaskStatus.Completed:
      return (
        <CalculationTaskResultsView
          calculationTask={calculationTask}
          onResetToDraft={refreshCalculationTask}
        />
      )
    case CalculationTaskStatus.Failed:
      return (
        <CalculationTaskFailedView
          calculationTask={calculationTask}
        />
      )
    case undefined:
      return (
        <CalculationTaskLoadingView />
      )
    default:
      return (
        <div>Ukjent status : (</div>
      )
  }
}


