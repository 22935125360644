import { FC } from "react"
import './signInPageTemplate.scoped.scss'

export interface SignInPageTemplateProps {

}

export const SignInPageTemplate: FC<SignInPageTemplateProps> = (props) => {
  return (
    <div className="sign-in-page-template">
      <div className="panel">
        <h1 className="heading">Logg inn</h1>
        {props.children}
      </div>
    </div>
  )
}
