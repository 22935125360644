import { FC } from "react"
import 'components/common/modals/confirmationModal/confirmationModal.scoped.scss'
import { ModalProps } from "services/modal/modalService"
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { DangerButton } from "components/common/buttons/dangerButton/dangerButton"

export interface ConfirmationPromptProps extends ModalProps {
  heading?: string
  message?: string
  confirmButtonText?: string
  confirmButtonStyle?: "primary" | "danger"
  cancelButtonText?: string
}

export const ConfirmationModal: FC<ConfirmationPromptProps> = (props) => {
  const confirm = () => {
    return props.modalContext.close(true)
  }
  const dismiss = () => {
    return props.modalContext.close(false)
  }

  return (
    <ModalBase onDismiss={dismiss}
      heading={props.heading}
      actions={
        <>
          <LightButton onClick={dismiss}>{props.cancelButtonText ?? "Avbryt"}</LightButton>
          {(props.confirmButtonStyle === undefined || props.confirmButtonStyle === "primary") && (
            <PrimaryButton onClick={confirm}>{props.confirmButtonText ?? "OK"}</PrimaryButton>
          )}
          {props.confirmButtonStyle === "danger" && (
            <DangerButton onClick={confirm}>{props.confirmButtonText ?? "OK"}</DangerButton>
          )}
        </>
      }
    >
      <div className="confirmation-prompt column gap4">
        {props.children ?? (
          <p>{props.message}</p>
        )}
      </div>
    </ModalBase>
  )
}

