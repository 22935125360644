import {
  createContext,
  FC,
  useContext,
  useEffect,
  useState,
} from "react"
import { HubConnectionBuilder } from "@microsoft/signalr"

import { useAuthService } from "services/auth/authService"
import {
  CalculationProgressUpdateMessage,
  CalculationTaskFailedMessage,
  NewCalculationResultMessage,
} from "services/signalrService/messages"

export interface SignalrService {
  onNewCalculationResult: (callback: (message: NewCalculationResultMessage) => void) => void
  onCalculationTaskFailed: (callback: (message: CalculationTaskFailedMessage) => void) => void
  onCalculationProgressUpdate: (callback: (message: CalculationProgressUpdateMessage) => void) => void
}

const SignalrServiceContext = createContext<SignalrService | undefined>(undefined)

export const SignalrServiceProvider: FC = ({ children }) => {
  const authService = useAuthService()
  const signalrConnection = new HubConnectionBuilder()
    .withUrl('/.signalr/sicalc-hub', {
      accessTokenFactory: () => `${authService.currentUser?.token}`,
    })
    .withAutomaticReconnect()
    .build()

  signalrConnection.on('NewCalculationResult', (message: NewCalculationResultMessage) => {
    console.info(`Task ${message.calculationTaskId} has completed successfully`, message)
  })
  signalrConnection.on('CalculationTaskFailed', (message: CalculationTaskFailedMessage) => {
    console.info(`Task ${message.calculationTaskId} has failed`, message)
  })
  signalrConnection.on('CalculationProgressUpdate', (message: CalculationProgressUpdateMessage) => {
    console.info(`Task ${message.calculationTaskId} is ${message.progressPercent} % complete`, message)
  })

  signalrConnection.start()
    .then(() => {
      console.info("SignalR connection established")
    })
    .catch(() => {
      console.error("Failed to connect to signalR")
    })

  const useOnNewCalculationResult = (callback: (message: NewCalculationResultMessage) => void) => {
    useEffect(() => {
      signalrConnection.on('NewCalculationResult', callback)
      return () => signalrConnection.off('NewCalculationResult', callback)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  }

  const useOnCalculationTaskFailed = (callback: (message: CalculationTaskFailedMessage) => void) => {
    useEffect(() => {
      signalrConnection.on('CalculationTaskFailed', callback)
      return () => signalrConnection.off('CalculationTaskFailed', callback)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  }

  const useOnCalculationProgressUpdate = (callback: (message: CalculationProgressUpdateMessage) => void) => {
    useEffect(() => {
      signalrConnection.on('CalculationProgressUpdate', callback)
      return () => signalrConnection.off('CalculationProgressUpdate', callback)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
  }

  return (
    <SignalrServiceContext.Provider
      value={{
        onNewCalculationResult: useOnNewCalculationResult,
        onCalculationProgressUpdate: useOnCalculationProgressUpdate,
        onCalculationTaskFailed: useOnCalculationTaskFailed,
      }}
    >
      {children}
    </SignalrServiceContext.Provider>
  )
}

export const useSignalrService = () => {
  const context = useContext<SignalrService | undefined>(SignalrServiceContext)
  if (!context) {
    const serviceName = Object.keys({ SignalrServiceContext })[0]
    throw new Error(serviceName + " was not provided. "
      + "Make sure the component is a child of the required service provider")
  }
  return context
}
