import 'components/common/formFields/SearchableSelectField/SearchableSelectField.scoped.scss'
import ReactSelect from "react-select"
import { FocusEvent } from "react"

export interface SearchableSelectFieldProps {
  options: SearchableSelectFieldProps_Option[] | undefined
  isLoading?: boolean
  value: SearchableSelectFieldProps_Option | undefined
  onChange: (newValue: string | number | undefined) => void
  onBlur?: (event: FocusEvent) => void
}

interface SearchableSelectFieldProps_Option {
  label: string
  value: string | number
}

export const SearchableSelectField = (props: SearchableSelectFieldProps) => {
  return (
    <ReactSelect
      placeholder="Velg ..."
      menuPortalTarget={document.body}
      options={props.options}
      isLoading={props.isLoading}
      value={props.value}
      onChange={(newValue) => props.onChange(newValue?.value)}
      onBlur={props.onBlur}
      menuPlacement={"auto"}
      styles={{
        menuList: (provided, state) => ({
          ...provided,
          paddingTop: 0,
          paddingBottom: 0,
        }),
        menuPortal: base => ({ ...base, zIndex: 9999999 }),
        menu: base => ({
          ...base,
          marginTop: '0.25rem',
        }),
        control: (styles) => ({
          ...styles,
          cursor: 'pointer',
        }),
      }}
    />
  )
}

