import {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react"
import { ModalContext } from "services/modal/modalContext"
import { TypedEmitter } from "tiny-typed-emitter"
import { useLocation } from "react-router-dom"

interface ModalService {
  showModal: (createComponentFunction: (modalContext: ModalContext) => ReactNode) => Promise<unknown>
  currentModal?: ShowModalEvent
  onShowModal: (callback: (event: ShowModalEvent) => void) => void
  removeOnShowModalListener: (callback: (event: ShowModalEvent) => void) => void
}

export interface ModalProps {
  modalContext: ModalContext
}

export interface ShowModalEvent {
  component: ReactNode
  modalContext: ModalContext
}

interface ModalServiceEvents {
  'open': (event: ShowModalEvent) => void
}

const ModalServiceContext = createContext<ModalService | undefined>(undefined)

export const ModalServiceProvider: FC = ({ children }) => {
  const [currentModal, setCurrentModal] = useState<ShowModalEvent>()
  const emitter = new TypedEmitter<ModalServiceEvents>()
  const location = useLocation()
  const closeCurrentModal = () => {
    if (currentModal) {
      currentModal.modalContext.dismiss()
    }

    setCurrentModal(undefined)
  }

  const onShowModal = (callback: (event: ShowModalEvent) => void) => {
    emitter.addListener('open', callback)
  }

  const removeOnShowModalListener = (callback: (event: ShowModalEvent) => void) => {
    emitter.removeListener('open', callback)
  }

  const showModal = (createComponentFunction: (modalContext: ModalContext) => ReactNode) => {
    return new Promise((resolve, reject) => {
      const modalContext = new ModalContext(resolve, reject)
      const showModalEVent = {
        component: createComponentFunction(modalContext),
        modalContext,
      }
      modalContext.onClose(closeCurrentModal)
      setCurrentModal(showModalEVent)
      emitter.emit('open', showModalEVent)
    })
  }

  useEffect(closeCurrentModal, [location.pathname])

  return (
    <ModalServiceContext.Provider
      value={{
        showModal,
        currentModal,
        onShowModal,
        removeOnShowModalListener,
      }}
    >
      {children}
    </ModalServiceContext.Provider>
  )
}

export const useModalService = () => {
  const context = useContext<ModalService | undefined>(ModalServiceContext)
  if (!context) {
    const serviceName = Object.keys({ ModalServiceContext })[0]
    throw new Error(serviceName + " was not provided. "
      + "Make sure the component is a child of the required service provider")
  }
  return context
}
