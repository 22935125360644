import './textAreaField.scoped.scss'
import { ChangeEvent, FocusEvent, ReactNode } from "react"

export interface TextAreaFieldProps {
  label: string | ReactNode
  name: string
  value: string | number
  isInvalid: boolean
  errors?: string
  onChange: (event: ChangeEvent) => void
  onBlur: (event: FocusEvent) => void
  rows?: number
}

export const TextAreaField = (props: TextAreaFieldProps) => {
  return (
    <label className="select-field column">
      <span className={'form-label'}>{props.label}</span>
      <textarea
        name={props.name}
        value={props.value}
        rows={props.rows}
        onChange={props.onChange}
        onBlur={props.onBlur}
        className={`input text-area-field ${props.isInvalid ? 'invalid' : ''}`}
      />
      {props.isInvalid && (
        <div className={'validation-error column gap1 mt1'}>
          {props.errors}
        </div>
      )}
    </label>
  )
}

