import { CreateCustomScenarioRequest } from "../requests/createCustomScenarioRequest"
import { CustomScenarioListResponse } from "../responses/customScenarioListResponse"
import { CustomScenarioResponse } from "../responses/customScenarioResponse"
import { AxiosInstance } from "axios"

export class CustomScenarioApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async getAll(calculationTaskId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/custom-scenarios`
    const response = await this.sicalcClient.get<CustomScenarioListResponse>(url)
    return response.data
  }

  public async create(calculationTaskId: string, request: CreateCustomScenarioRequest) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/custom-scenarios`
    const response = await this.sicalcClient.post<CustomScenarioResponse>(url, request)
    return response.data
  }

  public async update(calculationTaskId: string, customScenarioId: string, request: CreateCustomScenarioRequest) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/custom-scenarios/${customScenarioId}`
    const response = await this.sicalcClient.put<CustomScenarioResponse>(url, request)
    return response.data
  }

  public async delete(calculationTaskId: string, customScenarioId: string) {
    const url = `/api/v1/calculation-tasks/${calculationTaskId}/custom-scenarios/${customScenarioId}`
    await this.sicalcClient.delete(url)
  }
}

