import "./lightButton.scoped.scss"
import { FC } from "react"

export interface LightButtonProps {
  text?: string
  onClick?: () => void
}

export const LightButton: FC<LightButtonProps> = (props) => {

  return (
    <button className="button light" onClick={props.onClick}>
      { props.text !== undefined? props.text : props.children }
    </button>
  )
}
