import { ProjectResponse } from "../responses/projectResponse"
import { ProjectListResponse } from "../responses/projectListResponse"
import { AxiosInstance } from "axios"
import { CreateProjectRequest } from "services/sicalcApi/requests/createProjectRequest"
import { CreateProjectResponse } from "services/sicalcApi/responses/createProjectResponse"

export class ProjectApi {
  private sicalcClient: AxiosInstance

  constructor(sicalcClient: AxiosInstance) {
    this.sicalcClient = sicalcClient
  }

  public async get(projectId: string) {
    const url = `/api/v1/projects/${projectId}`
    const response = await this.sicalcClient.get<ProjectResponse>(url)
    return response.data
  }

  public async create(request: CreateProjectRequest) {
    const url = `/api/v1/projects`
    const response = await this.sicalcClient.post<CreateProjectResponse>(url, request)
    return response.data
  }

  public async getAll() {
    const url = `/api/v1/projects`
    const response = await this.sicalcClient.get<ProjectListResponse>(url)
    return response.data
  }

  public async delete(projectId: string) {
    const url = `/api/v1/projects/${projectId}`
    await this.sicalcClient.delete<void>(url)
  }
}

