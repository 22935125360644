import React, { FC } from "react"
import './CheckboxInput.scoped.scss'

export interface CheckboxInputProps {
  label: string
  value: boolean
  disabled?: boolean
  onChange: (value: boolean) => void
}

export const CheckboxInput: FC<CheckboxInputProps> = (props) => {
  return (
    <label className="checkbox-input row align-center">
      <input
        type="checkbox"
        checked={props.value}
        onChange={e => props.onChange(e.target.checked)}
        disabled={props.disabled}
      />
      <span className="label">
        { props.label }
      </span>
    </label>
  )
}

