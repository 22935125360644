import './multiToggle.scoped.scss'
import React, { useEffect, useState } from "react"

export interface MultiToggleProps<TValue> {
  value?: TValue
  options: {
    label: string
    value: TValue
  }[]
  onChange: (value: TValue) => void
}

export const MultiToggle = <TValue, >(props: MultiToggleProps<TValue>) => {
  const [ currentValue, setCurrentValue ] = useState<TValue | undefined>(props.value)

  useEffect(() => {
    setCurrentValue(props.value)
  }, [props.value])

  const onToggle = (value: TValue) => {
    setCurrentValue(value)
    props.onChange(value)
  }
  const isCurrentValue = (value: TValue | undefined) => {
    return value === currentValue
  }

  return (
    <div className="row button-panel">
      {React.Children.toArray(props.options.map(option => (
        <button
          onClick={() => onToggle(option.value)}
          className={`button panel-button ${isCurrentValue(option.value) ? 'active' : ''}`}
          type="button"
        >{option.label}</button>
      )))}
    </div>
  )
}

