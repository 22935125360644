import { FC } from "react"
import './stackedListItem.scoped.scss'

export interface StackedListItemProps {

}

export const StackedListItem: FC<StackedListItemProps> = (props) => {
  return (
    <li className="stacked-list-item">
      {props.children}
    </li>
  )
}
