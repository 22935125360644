import './noiseSourceInput.scoped.scss'
import { SearchableSelectField } from "components/common/formFields/SearchableSelectField/SearchableSelectField"
import { FocusEvent, useCallback, useEffect, useState } from "react"
import { NoiseSourceResponse } from "services/sicalcApi/responses/noiseSourceResponse"
import { useSicalcApiService } from "services/sicalcApi/sicalcApiService"

export interface NoiseSourceInputProps {
  value: string | number | undefined
  onChange: (newValue: string | number | undefined) => void
  onBlur?: (event: FocusEvent) => void
  isLoading?: boolean
}

export const NoiseSourceInput = (props: NoiseSourceInputProps) => {
  const { noiseSourceApi } = useSicalcApiService()

  const [ noiseSources, setNoiseSources ] = useState<NoiseSourceResponse[]>()
  const [ noiseSourcesLoading, setNoiseSourcesLoading ] = useState<boolean>(true)

  const getNoiseSources = useCallback(async () => {
    setNoiseSourcesLoading(true)
    const response = await noiseSourceApi.getAll()
    setNoiseSourcesLoading(false)
    return response.noiseSources
  }, [noiseSourceApi])

  useEffect(() => {
    getNoiseSources()
      .then(setNoiseSources)
  }, [getNoiseSources])


  const getNoiseSourceValueObject = () => {
    // react-select uses a key-value pair for the value instead of just the value like any other field
    const selectedOption = noiseSources?.find(n => n.id === props.value)
    return selectedOption !== undefined
      ? {
        label: selectedOption.name,
        value: selectedOption.id,
      }
      : undefined
  }

  return (
    <SearchableSelectField
      value={getNoiseSourceValueObject()}
      onChange={props.onChange}
      onBlur={props.onBlur}
      options={noiseSources?.map(n => ({
        label: n.name,
        value: n.id,
      }))}
      isLoading={noiseSourcesLoading}
    />
  )
}

