import { FC } from "react"
import 'components/headerContextMenu/headerContextMenu.scoped.scss'
import { Dropdown, DropdownDirection } from "components/dropdown/dropdown"

export interface HeaderContextMenuProps {

}

export const HeaderContextMenu: FC<HeaderContextMenuProps> = (props) => {
  return (
    <Dropdown
      direction={DropdownDirection.bottomRight}
      trigger={
        <button className="button header-menu-button">
          <i className="mdi mdi-24px mdi-chevron-down"></i>
        </button>
      }>
      <div className="header-menu-content">
        {props.children}
      </div>
    </Dropdown>
  )
}

