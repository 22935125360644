import './ScenarioPickerWorstCaseScenario.scoped.scss'
import { ClearButton } from "components/common/buttons/clearButton/clearButton"
import { ToolTip } from "components/common/toolTip/toolTip"
import { CheckboxInput } from "components/common/formFields/CheckboxInput/CheckboxInput"
import { NoiseSourceResponse_Scenario } from "services/sicalcApi/responses/noiseSourceResponse"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { CalculationTaskResponse_WorstCaseScenarioSettings } from "services/sicalcApi/responses/calculationTaskResponse"
import { getConstituentScenarioLabelsForWorstCaseScenario } from "utils/getConstituentScenarioLabelsForWorstCaseScenario"
import React from "react"

export interface ScenarioPickerWorstCaseScenarioProps {
  availableScenarios?: NoiseSourceResponse_Scenario[]
  customScenarios?: CustomScenarioResponse[]
  worstCaseScenario: CalculationTaskResponse_WorstCaseScenarioSettings
  onClickDelete: () => void
  onClickEdit: () => void
}

export const ScenarioPickerWorstCaseScenario = (props: ScenarioPickerWorstCaseScenarioProps) => {
  const getScenarioNamesJsx = () => {
    if (props.customScenarios === undefined || props.availableScenarios === undefined) {
      return
    }

    const scenarioNames = getConstituentScenarioLabelsForWorstCaseScenario(
      props.worstCaseScenario,
      props.availableScenarios,
      props.customScenarios,
    )

    return (
      <>
        <span className="constituent-scenario-label">{scenarioNames.firstScenario.label}</span>
        <span className="constituent-scenario-label">{scenarioNames.secondScenario.label}</span>
      </>
    )
  }

  return (
    <div className="worst-case-scenario row space-between align-center">
      <div className="row align-center gap2">
        {/* <CheckboxInput label={""} value={true} onChange={() => {}} disabled /> */}
        <i className="worst-case-scenario-icon mdi mdi-merge" />
        <div className="column gap2">
          <span>Verste tilfelle</span>
          {getScenarioNamesJsx()}
        </div>
      </div>
      <div className="row gap1">
        <ToolTip content="Rediger" defaultPosition="top-end">
          <ClearButton iconName="pencil" onClick={props.onClickEdit} ariaLabel="Rediger"></ClearButton>
        </ToolTip>
        <ToolTip content="Slett" defaultPosition="top-end">
          <ClearButton iconName="delete" onClick={props.onClickDelete} ariaLabel="Slett"></ClearButton>
        </ToolTip>
      </div>
    </div>
  )
}

