import './createWorstCaseScenarioModal.scoped.scss'
import { ModalProps } from "services/modal/modalService"
import { ModalBase } from "components/common/modals/modalBase/modalBase"
import { LightButton } from "components/common/buttons/lightButton/lightButton"
import { PrimaryButton } from "components/common/buttons/primaryButton/primaryButton"
import React, { useEffect, useState } from "react"
import * as yup from "yup"
import { useFormik } from "formik"
import { CustomScenarioResponse } from "services/sicalcApi/responses/customScenarioResponse"
import { NoiseSourceResponse_Scenario } from "services/sicalcApi/responses/noiseSourceResponse"
import { SelectField } from "components/common/formFields/selectField/selectField"
import { ScenarioType } from "services/sicalcApi/sharedEntities/scenarioType"


interface FormDataModel {
  firstScenarioId: string
  secondScenarioId: string
}

const formValidationSchema = yup.object().shape({
  firstScenarioId: yup.string()
    .required("Påkrevd")
    .test("mustBeDifferentFromSecondScenario", "Scenariene må være forskjellige", (firstScenarioId, context) => {
      return firstScenarioId !== context.parent.secondScenarioId
    }),
  secondScenarioId: yup.string()
    .required("Påkrevd")
    .test("mustBeDifferentFromFirstScenario", "Scenariene må være forskjellige", (secondScenarioId, context) => {
      return secondScenarioId !== context.parent.firstScenarioId
    }),
})

export interface CreateWorstCaseScenarioModalProps extends ModalProps {
  availableScenarios?: NoiseSourceResponse_Scenario[]
  customScenarios?: CustomScenarioResponse[]
  initialValues?: {
    firstScenarioId: string
    firstScenarioType: ScenarioType
    secondScenarioId: string
    secondScenarioType: ScenarioType
  }
}

export interface CreateWorstCaseScenarioModalResult {
  firstScenarioId: string
  firstScenarioType: ScenarioType
  secondScenarioId: string
  secondScenarioType: ScenarioType
}

interface DropdownOption {
  name: string
  value: string
}

export const CreateWorstCaseScenarioModal = (props: CreateWorstCaseScenarioModalProps) => {
  const [ options, setOptions ] = useState<DropdownOption[]>([])

  useEffect(() => {
    const options: DropdownOption[] = []
    const scenarioOptions = props.availableScenarios
      ?.map<DropdownOption>(s => ({
        name: s.name,
        value: s.id,
      }))

    if (scenarioOptions) {
      options.push(...scenarioOptions)
    }

    const customScenarioOptions = props.customScenarios
      ?.map<DropdownOption>(s => ({
        name: s.name,
        value: s.id,
      }))
    if (customScenarioOptions) {
      options.push(...customScenarioOptions)
    }
    setOptions(options)
  }, [props.availableScenarios, props.customScenarios])

  const initialValues: FormDataModel = {
    firstScenarioId: props.initialValues?.firstScenarioId ?? '',
    secondScenarioId:  props.initialValues?.secondScenarioId ?? '',
  }

  const handleSubmission = async (values: FormDataModel) => {
    let firstScenarioType: ScenarioType | undefined
    if (props.availableScenarios
      ?.some(s => s.id === values.firstScenarioId) ) {
      firstScenarioType = ScenarioType.Scenario
    } else if (props.customScenarios
      ?.some(c => c.id === values.firstScenarioId)) {
      firstScenarioType = ScenarioType.CustomScenario
    }
    else {
      const message = `The first scenario ${values.firstScenarioId} was neither`
        + `in the scenarios nor custom scenarios list`
      throw new Error(message)
    }

    let secondScenarioType: ScenarioType | undefined
    if (props.availableScenarios
      ?.some(s => s.id === values.secondScenarioId) ) {
      secondScenarioType = ScenarioType.Scenario
    } else if (props.customScenarios
      ?.some(c => c.id === values.secondScenarioId)) {
      secondScenarioType = ScenarioType.CustomScenario
    }
    else {
      const message = `The first scenario ${values.secondScenarioId} was neither`
        + `in the scenarios nor custom scenarios list`
      throw new Error(message)
    }

    const result: CreateWorstCaseScenarioModalResult = {
      firstScenarioId: values.firstScenarioId,
      firstScenarioType,
      secondScenarioId: values.secondScenarioId,
      secondScenarioType,
    }
    props.modalContext.close(result)
  }

  const form = useFormik({
    initialValues,
    validationSchema: formValidationSchema,
    onSubmit: handleSubmission,
  })
  const dismiss = () => {
    props.modalContext.dismiss()
  }

  return (
    <ModalBase
      onDismiss={dismiss}
      heading="Verste tilfelle-scenario"
      actions={
        <>
          <LightButton
            onClick={dismiss}
          >
            Avbryt
          </LightButton>
          <PrimaryButton
            onClick={form.handleSubmit}
            submit={true}
          >
            Lagre
          </PrimaryButton>
        </>
      }
    >
      <div className="worst-case-scenario-modal column gap4">
        <SelectField
          label={"Scenario 1"}
          options={options}
          value={form.values.firstScenarioId}
          onChange={e => form.setFieldValue('firstScenarioId', e.target.value)}
          onBlur={() => form.handleBlur('firstScenarioId')}
          isInvalid={form.touched.firstScenarioId === true && form.errors.firstScenarioId !== undefined}
          errors={form.errors.firstScenarioId}
        />
        <SelectField
          label={"Scenario 2"}
          value={form.values.secondScenarioId}
          options={options}
          onChange={e => form.setFieldValue('secondScenarioId', e.target.value)}
          errors={form.errors.secondScenarioId}
          onBlur={() => form.handleBlur('secondScenarioId')}
          isInvalid={form.touched.secondScenarioId === true && form.errors.secondScenarioId !== undefined}
        />
      </div>
    </ModalBase>
  )
}
