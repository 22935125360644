import { useModalService } from "services/modal/modalService"

export interface ModalRootProps {

}

export const ModalRoot = (props: ModalRootProps) => {
  const { currentModal } = useModalService()

  return <>{currentModal?.component}</>
}
